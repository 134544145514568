import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import { ApiRoutes } from "../../constants/apiroutes";
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";
import DefaultImg from "../../assets/Images/Icon/no_image.png";
import { alertService, AlertType } from "../../services/alert.service";
import InputText from "../../components/form/inputText";
import DatePicker from "react-datepicker";
import { Enums } from "../../constants/enums";
import Swal from "sweetalert2";
import { Breadcrumb, Button ,InputGroup , Form , Card} from "react-bootstrap";
import NewLoader from "../loader/NewLoader";

const ClientUsers = ({ RoleRights = null, client_id = null }) => {
    const [txtSearch, setTxtSearch] = useState("");
    const [filterStartDate, setFilterStartDate] = useState(null);
    const [filterEndDate, setFilterEndDate] = useState(null);
    const [dataTableLoading, setDataTableLoading] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [companyTotalRows, setGuardTotalRows] = useState(0);
    const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
    const [usersList, setUsersList] = useState({
        isData: false,
        data: [],
    });
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [filterStatus, setFilterStatus] = useState(null);

    const navigate = useNavigate();

    React.useEffect(() => {
        getUsersList("", "", 1);
    }, [pageNo, perPageRecords]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPageRecords(newPerPage);
    };

    function filterCompaniesList() {
        getUsersList("", "", 1);
    }

    const resetFilter = () => {
        setFromDate(null)
        setToDate(null)
        setTxtSearch("")
        setFilterStatus(null)
        getUsersList("", "", 0);
    };
    const searchData = (e) => {
        e.preventDefault();

        let isValidate = true;
        if (toDate || fromDate) {
            if (toDate && fromDate) {
                const dateFrom = new Date(fromDate);
                const dateTo = new Date(toDate);
                if (dateFrom > dateTo) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: "End date must be after start date",
                    });
                    isValidate = false;
                }
            }
        }
        if (isValidate) {
            getUsersList("", "", 1);
        }
    };


    const handleDelete = async (e, client_contact_id) => {

      e.preventDefault();
  
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        let res = await APICall(ApiRoutes.UserDelete, {
          client_contact_id: client_contact_id
        })
        if (res.status === 1) {
          
          alertService.alert({
            type: AlertType.Success,
            message: res.message,
          });
          getUsersList("", "", 1);
        }
      }
    }

    React.useEffect(() => {
        getUsersList("", "", 1);
    }, [txtSearch]);

    async function getUsersList(column = "", sortDirection = "", isFilter = 0) {
        setDataTableLoading(true);
        setUsersList({ isData: false, data: [] });
        let data = {
            page: pageNo,
            client_id: client_id,
            limit: perPageRecords,
            order: { column: column, dir: sortDirection },
            is_filter: isFilter,
            filter: {
                search_text: txtSearch,
                status: filterStatus,
                start_date:
                    fromDate != null
                        ? commonService.convertLocalToUTC(
                            commonService.formatDate(fromDate) + " 00:00:00"
                        )
                        : null,
                end_date:
                    toDate != null
                        ? commonService.convertLocalToUTC(
                            commonService.formatDate(toDate) + " 23:59:59"
                        )
                        : null,
            },
        }
        if (client_id) {
            data = {
                ...data,
                client_id: client_id
            }
        }

        const requestMetadata = await APICall(ApiRoutes.UserList, data);

        if (requestMetadata) {
            setUsersList({ isData: true, data: requestMetadata.data.list });

            setGuardTotalRows(requestMetadata.data.total);
            setDataTableLoading(false);
        }
      
    }

    const handleSort = async (column, sortDirection) => {
        getUsersList(column.sortField, sortDirection ,1);
    };

    const onSwitchToggle = async (value, item, index) => {
        const data = await APICall(ApiRoutes.UserStatusUpdate, {
            id: item.client_contact_id,
            is_active: value ? "1" : "0",
        });
        if (data) {
            setUsersList((prevState) => ({
                ...prevState,
                data: prevState.data.map((MapItem, MapIndex) => ({
                    ...MapItem,
                    is_active:
                        MapIndex === index ? (value ? "1" : "0") : MapItem.is_active,
                })),
            }));
        }
    };

    const companyTblColumns = [
        {
            name: "#",
            selector: (row) => row.full_name,
            sortable: false,
            width: "80px",
            cell: (row, index, column, id) => {
                return (
                    <img
                        src={
                            row.profile_img
                                ? process.env.REACT_APP_ASSETS_URL + row.profile_img
                                : DefaultImg
                        }
                        className="round"
                        alt="user"
                        width="40"
                        height="40"
                        object-fit="cover"
                    />
                );
            },
        },
        {
            name: "Name",
            selector: (row) => row.full_name,
            sortable: true,
            sortField: "full_name",
        },
        {
            name: "Corporate Email",
            selector: (row) => row.email,
            sortable: true,
            sortField: "email",
        },
        {
            name: "Phone",
            selector: (row) => row.phone,
            sortable: true,
            sortField: "phone",
        },
        {
            name: "Position",
            selector: (row) => row.position,
            sortable: true,
            sortField: "position",
        },
        {
            name: "Status",
            selector: (row) => row.is_active,
            sortable: true,
            sortField: "is_active",
            cell: (row, index, column, id) => {
                return (
                    <span className="justify-content-center align-items-center d-flex">
                        <Switch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onChange={(value) => onSwitchToggle(value, row, index)}
                            checked={row.is_active === "1" ? true : false}
                            height={20}
                            width={40}
                            disabled={RoleRights ? !RoleRights.is_update : true}
                            onColor="#263A96"
                        />
                    </span>
                );
            },
        },
        {
            name: "Created On",
            selector: (row) => row.created_at,
            sortable: true,
            sortField: "created_at",
            cell: (row, index, column, id) =>
                commonService.convertUTCToLocal(row.created_at),
        },
        {
            name: "Action",
            selector: (row) => row.year,
            omit: RoleRights ? !RoleRights.is_update : true,
            cell: (row, index, column, id) =>
             
                    <div className="justify-content-center align-items-center d-flex">
                          { RoleRights.is_update === 1 && (<>
                          <div className="cursor-pointer me-2"
                            onClick={() => {
                                navigate("edit", {
                                    state: {
                                        id: row.client_contact_id,
                                    },
                                });
                            }}
                        >
                            <i className="fa fa-edit text-primary"></i>
                        </div>
                         <div className="cursor-pointer me-2"
                         onClick={() => {
                             navigate(`/user/view/${row.client_contact_id}`);
                         }}
                     >
                         <i className="fa fa-eye text-primary"></i>
                     </div>
                     <div className="cursor-pointer"
                            onClick={(e) => {
                               handleDelete(e , row.client_contact_id)
                            }}
                        >
                            <i className="fa fa-trash text-danger"></i>
                        </div>
                     
                     </>)
                        }
                    </div>
                ,
        },
    ];
    const handleFilterStatus = (value)=>{
        if (value) {
          setFilterStatus(parseInt(value));
        } else {
          setFilterStatus(null);
        }
      }

    return (<>
    <div>
        <Card>
          <Card.Body className="p-3">
            <div className="row">

              <div className="col-3">
                <InputGroup>
                  <DatePicker
                    className="datepicker form-control"
                    selected={fromDate ? fromDate : ""}
                    dateFormat="MM-dd-yyyy"
                    placeholderText="Start Date"
                    onChange={(date) => {
                      if (date) {
                        setFromDate(date);
                      } else {
                        setFromDate(null);
                      }
                    }}
                  />
                </InputGroup>
              </div>
              <div className="col-3">
                <div>
                  <InputGroup>
                    <DatePicker
                      className="datepicker form-control"
                      selected={toDate ? toDate : ""}
                      dateFormat="MM-dd-yyyy"
                      placeholderText="End Date"
                      onChange={(date) => {
                        if (date) {
                          setToDate(date);
                        } else {
                          setToDate(null);
                        }
                      }}
                    />
                  </InputGroup>
                </div>

              </div>
              <div className="col-3">
                <Form.Select onChange={(e) => { handleFilterStatus(e.target.value) }}>
                  <option value="" selected={filterStatus == null  && true}>
                    Select User Status
                  </option>
                  <option value="1" selected={filterStatus == 1 && true}>
                    Active
                  </option>
                  <option value="0" selected={filterStatus == 0 && true}>
                    Inactive
                  </option>

                </Form.Select>
              </div>
              <div className="col-3">
                <div className="d-flex">
                  <div className="me-2">
                    <Button
                      onClick={searchData}
                      variant="outline-primary"
                      type="button"
                    >
                      <i className="fa fa-search"></i>
                    </Button>
                  </div>
                  <div className="ms-">
                    <Button
                      onClick={resetFilter}
                      variant="outline-primary"
                      type="button"
                    >
                      <i className="fa fa-refresh"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

          </Card.Body>
        </Card>
      </div>
      <DataTable
        columns={companyTblColumns}
        data={usersList.data}
        progressPending={dataTableLoading}
        progressComponent={<NewLoader />}
        pagination
        paginationServer
        paginationTotalRows={companyTotalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        paginationPerPage={perPageRecords}
        actions={<>
            <div className="col-4">
              <InputText
                formGroupClass={"mb-0"}
                size={'12'}
                type={"text"}
                Label={false}
                placeholder={"Search..."}
                value={txtSearch}
                onChange={(value) => {
                  if (value) {
                    setTxtSearch(value)
                  } else {
                    setTxtSearch(value)
                  }
                }}
              />
            </div>
          </>}
    />
    </>);
}

export default ClientUsers;