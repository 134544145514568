import React, { useState } from "react";
import { Breadcrumb, Button, InputGroup, Form, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import Switch from "react-switch";

// import FilterComponent from "../../components/common/FilterComponent";
import { ApiRoutes } from "../../constants/apiroutes";
import { Enums } from "../../constants/enums";
// import NoDataFound from "../../components/common/NoDataFound";
import getUserAccess from "../../helpers/userAccess";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import { alertService, AlertType } from "../../services/alert.service";
import InputText from "../../components/form/inputText";
import DatePicker from "react-datepicker";
import NewLoader from "../../components/loader/NewLoader";

export default function CompanyBranchList({company_id}) {

  const navigate = useNavigate();
  const [txtSearch, setTxtSearch] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [branchTotalRows, setBranchTotalRows] = useState(0);
  const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
  const [branchList, setBranchList] = useState({
    isData: false,
    data: [],
  });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);

  const userData = JSON.parse(commonService.getItem("user"));

  const RoleRights = getUserAccess(Enums.UserRights.branch);

  const userType = userData.association_type_term;

  const getBranchTblColumns = () => {
    const branchTblColumns = [
      {
        name: "Branch Manager",
        selector: (row) => row.branch_name,
        sortable: true,
        sortField: "branch_name",
      },
      // {
      //   name: "Company Name",
      //   selector: (row) => row.company_name,
      //   sortable: true,
      //   sortField: "company_name",
      // },
      {
        name: "Email",
        selector: (row) => row.branch_email,
        sortable: true,
        sortField: "Email",
      },
      {
        name: "Phone",
        selector: (row) => row.branch_phone,
        sortable: true,
        sortField: "branch_phone",
      },
      // {
      //   name: "Registration No",
      //   selector: (row) => row.branch_register_no,
      //   sortable: true,
      //   sortField: "branch_register_no",
      // },
      {
        name: "Total Guards",
        selector: (row) => row.total_guards_count,
        sortable: true,
        sortField: "total_guards_count",
      },
      {
        name: "Total Incharge",
        selector: (row) => row.total_incharges_count,
        sortable: true,
        sortField: "total_incharges_count",
      },
      {
        name: "Status",
        selector: (row) => row.is_active,
        sortable: true,
        sortField: "is_active",
        cell: (row, index, column, id) => {
          return (
            <span className="justify-content-center align-items-center d-flex">
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                onChange={(value) => ""}
                checked={row.is_active === "1" ? true : false}
                height={20}
                width={40}
                disabled={true}
                onColor="#263A96"
              />
            </span>
          );
        },
      },
      {
        name: "Total Contracts",
        selector: (row) => row.contract_counts,
        sortable: true,
        sortField: "contract_counts",
      },
      {
        name: "Start Date",
        selector: (row) => row.start_date,
        sortable: true,
        sortField: "start_date",
        omit: userType === Enums.UserRole.admin ? false : true,
        cell: (row, index, column, id) =>
          row.start_date ? commonService.convertUTCToLocal(row.start_date) : "",
      },
      {
        name: "Expiry Date",
        selector: (row) => row.end_date,
        sortable: true,
        omit: userType === Enums.UserRole.admin ? false : true,
        sortField: "end_date",
        cell: (row, index, column, id) =>
          row.end_date ? commonService.convertUTCToLocal(row.end_date) : "",
      },
      {
        name: "Action",
        selector: (row) => row.year,
        cell: (row, index, column, id) =>

          <div className="justify-content-center align-items-center d-flex">
            <div className="cursor-pointer" onClick={() => {
              navigate(`/branch/view/${row.branch_id}`)
            }}
            >
              <i className="fa fa-eye text-primary"></i>
            </div>
          </div>
        ,
      },
      // {
      //   name: "Created On",
      //   selector: (row) => row.created_at,
      //   sortable: true,
      //   sortField: "created_at",
      //   cell: (row, index, column, id) =>
      //     commonService.convertUTCToLocal(row.created_at),
      // },
    ];

    if (userData.association_type_term !== "admin") {
      const newTblData = branchTblColumns.filter(
        (filterItem) => filterItem.sortField !== "company_name"
      );
      return newTblData;
    }

    return branchTblColumns;
  };

  const history = useNavigate();

  React.useEffect(() => {
    getBranchList("", "", 1);
  }, [pageNo, perPageRecords]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };

  function filterCompaniesList() {
    getBranchList("", "", 1);
  }
  const resetFilter = () => {
    setFromDate(null)
    setToDate(null)
    setTxtSearch("")
    setFilterStatus(null)
    getBranchList("", "", 0);
  };

  const searchData = (e) => {
    e.preventDefault();

    let isValidate = true;
    if (toDate || fromDate) {
      if (toDate && fromDate) {
        const dateFrom = new Date(fromDate);
        const dateTo = new Date(toDate);
        if (dateFrom > dateTo) {
          alertService.alert({
            type: AlertType.Error,
            message: "End date must be after start date",
          });
          isValidate = false;
        }
      }
    }
    if (isValidate) {
      getBranchList("", "", 1);
    }
  };

  React.useEffect(() => {
    getBranchList("", "", 1);
  }, [txtSearch]);

  async function getBranchList(column = "", sortDirection = "", isFilter = 0) {
    setDataTableLoading(true);
    setBranchList({ isData: false, data: [] });
    const requestMetadata = await APICall(ApiRoutes.BranchList, {
      page: pageNo,
      association_type_term:Enums.UserRole.company,
      association_id : company_id,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      is_filter: isFilter,
      filter: {
        search_text: txtSearch,
        status: filterStatus,
        start_date:
          fromDate != null
            ? commonService.convertLocalToUTC(
              commonService.formatDate(fromDate) + " 00:00:00"
            )
            : null,
        end_date:
          toDate != null
            ? commonService.convertLocalToUTC(
              commonService.formatDate(toDate) + " 23:59:59"
            )
            : null,
      },
    });

    if (requestMetadata) {
      setBranchList({ isData: true, data: requestMetadata.data.list });

      setBranchTotalRows(requestMetadata.data.total);
      setDataTableLoading(false);
    }
  }

  const handleSort = async (column, sortDirection) => {
    getBranchList(column.sortField, sortDirection, 1);
  };

  const onSwitchToggle = async (value, item, index) => {
    const data = await APICall(ApiRoutes.BranchStatusUpdate, {
      id: item.branch_id,
      is_active: value ? "1" : "0",
    });
    if (data) {
      setBranchList((prevState) => ({
        ...prevState,
        data: prevState.data.map((MapItem, MapIndex) => ({
          ...MapItem,
          is_active:
            MapIndex === index ? (value ? "1" : "0") : MapItem.is_active,
        })),
      }));
    }
  };

  const handleFilterStatus = (value) => {
    if (value) {
      setFilterStatus(parseInt(value));
    } else {
      setFilterStatus(null);
    }
  }

  return (  <>
    <DataTable
      columns={getBranchTblColumns()}
      data={branchList.data}
      progressPending={dataTableLoading}
      progressComponent={<NewLoader />}
      pagination
      paginationServer
      paginationTotalRows={branchTotalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      onSort={handleSort}
      paginationPerPage={perPageRecords}
      actions={<>
        <div className="col-4">
          <InputText
            formGroupClass={"mb-0"}
            size={'12'}
            type={"text"}
            Label={false}
            placeholder={"Search..."}
            value={txtSearch}
            onChange={(value) => {
              if (value) {
                setTxtSearch(value)
              } else {
                setTxtSearch(value)
              }
            }}
          />
        </div>
      </>}
    />
    {/* )} */}
  </>)
}
