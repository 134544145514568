import React, { useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import NewLoader from "../../../components/loader/NewLoader";

export default function CreateWizardThree({
  formInputs,
  setInput,
  formInputsErrors,
  onNextPress,
  onPreviousPress,
  inchargeList,
  dataInchargeTableLoading,
  inchargeTotalRows,
  inchargeHandlePerRowsChange,
  inchargeHandlePageChange,
  inchargeHandleSort,
  onInchargeSelectedRowsChange,
}) {
  const [validated, setValidated] = useState(false);
  const [selectableRowSelected, setSelectableRowSelected] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const companyTblColumns = [
    {
      name: "Name",
      selector: (row) => row.incharge_name,
      sortable: true,
      sortField: "incharge_name",
    },
    {
      name: "Email",
      selector: (row) => row.incharge_email,
      sortable: true,
      sortField: "incharge_email",
    },
    {
      name: "Phone",
      selector: (row) => row.incharge_phone,
      sortable: true,
      sortField: "incharge_phone",
    },
    {
      name: "ID Number",
      selector: (row) => row.id_number,
      sortable: true,
      sortField: "id_number",
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
      sortField: "gender",
    },
  ];

  return (
    <div className="bs-stepper-content p-4">
      <div className="content active dstepper-block">
        <div className="content-header"></div>
        <Form validated={validated} onSubmit={handleSubmit}>
          <DataTable
            columns={companyTblColumns}
            data={inchargeList.data}
            progressPending={dataInchargeTableLoading}
            progressComponent={<NewLoader />}
            pagination
            paginationServer
            paginationTotalRows={inchargeTotalRows}
            onChangeRowsPerPage={inchargeHandlePerRowsChange}
            onChangePage={inchargeHandlePageChange}
            onSort={inchargeHandleSort}
            selectableRowSelected={!selectableRowSelected ?  (row) => {
              setSelectableRowSelected(true);
              formInputs.guard_list.includes(row.guard_id);
              return formInputs.incharge_list.includes(row.incharge_id);
            }:false}
            selectableRows
            onSelectedRowsChange={(state) =>
              onInchargeSelectedRowsChange(state)
            }
          />
          {formInputsErrors?.incharge_list && (
            <Row>
              <span className="error">{formInputsErrors.incharge_list}</span>
            </Row>
          )}
          <Row>
            <div className="d-flex justify-content-between">
              <Button
                type="submit"
                variant="outline-primary"
                className="btn-15"
                onClick={onPreviousPress}
              >
                <i className="fa-regular fa-arrow-left"></i>{" "}
                <span className="align-middle d-sm-inline-block d-none">
                  Previous
                </span>
              </Button>
              <Button
                className="btn-15 "
                variant="primary"
                onClick={onNextPress}
              >
                <span className="align-middle d-sm-inline-block d-none">
                  Next
                </span>{" "}
                <i className="fa-regular fa-arrow-right"></i>
              </Button>
            </div>
          </Row>
        </Form>
      </div>
    </div>
  );
}
