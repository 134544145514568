import React, { useState } from "react";
import { Breadcrumb , Button , InputGroup , Form , Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { ApiRoutes } from "../../constants/apiroutes";
import { Enums } from "../../constants/enums";
import getUserAccess from "../../helpers/userAccess";
import { APICall } from "../../services/axiosService";
import { commonService, DisplayText } from "../../services/common.service";
import InputText from "../../components/form/inputText";
import DatePicker from "react-datepicker";
import { alertService, AlertType } from "../../services/alert.service";
import { GuardStatusEnum } from "../../constants";
import NewLoader from "../../components/loader/NewLoader";

export default function BranchInchargeList({branch_id}) {

    const userType = JSON.parse(commonService.getItem("user")).association_type_term;
    const [txtSearch, setTxtSearch] = useState("");
    const [filterStartDate, setFilterStartDate] = useState(null);
    const [filterEndDate, setFilterEndDate] = useState(null);
    const [dataTableLoading, setDataTableLoading] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [inchargeTotalRows, setInchargeTotalRows] = useState(0);
    const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
    const [inchargeList, setInchargeList] = useState({
      isData: false,
      data: [],
    });
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [filterStatus, setFilterStatus] = useState(null);
  
    const RoleRights = getUserAccess(Enums.UserRights.incharge);
  
  
    const handleAcceptStatus = async(incharge_id)=>{
      let res =  await APICall(ApiRoutes.acceptInchargeStatus ,{incharge_id : incharge_id})
      if(res.status === 1){
        getInchargeList("", "", 1);
      }
  }
  
    const companyTblColumns = [
      {
        name: "Incharge Name",
        selector: (row) => row.incharge_name,
        sortable: true,
        sortField: "incharge_name",
      },
      {
        name: "Email",
        selector: (row) => row.incharge_email,
        sortable: true,
        sortField: "incharge_email",
      },
      {
        name: "Phone",
        selector: (row) => row.incharge_phone,
        sortable: true,
        sortField: "incharge_phone",
      },
      {
        name: "ID Number",
        selector: (row) => row.id_number,
        sortable: true,
        sortField: "id_number",
      },
      {
        name: "Gender",
        selector: (row) => row.gender,
        sortable: true,
        sortField: "gender",
      },
      {
        name: "Approval Status",
        selector: (row) => row.status,
        sortable: true,
        sortField: "status",
        cell: (row, index, column, id) => {
          if(userType == Enums.UserRole.admin && row.status === Enums.GuardStatus.pending ){
            return (<Button type="button" onClick={(e)=>{
              handleAcceptStatus(row.incharge_id)
            }} style={{ fontSize : "10px" }} size="sm">
              Activate
            </Button>)
          }else{
            return (
              <span className="justify-content-center align-items-center d-flex">
                {GuardStatusEnum[row.status]}
              </span>
            );
          }
         
        },
      },
      {
        name: "Status",
        selector: (row) => row.is_active,
        sortable: true,
        sortField: "is_active",
        cell: (row, index, column, id) => {
          return (
            <span className="justify-content-center align-items-center d-flex">
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                onChange={(value) => onSwitchToggle(value, row, index)}
                checked={row.is_active === "1" ? true : false}
                height={20}
                width={40}
                disabled={true}
                onColor="#263A96"
              />
            </span>
          );
        },
      },
      {
        name: "Created On",
        selector: (row) => row.created_at,
        sortable: true,
        sortField: "created_at",
        cell: (row, index, column, id) =>
          commonService.convertUTCToLocal(row.created_at),
      },
      {
        name: "Action",
        selector: (row) => row.year,
        cell: (row, index, column, id) =>
          <div className="justify-content-center align-items-center d-flex">
            <div className="cursor-pointer" onClick={() => {
              navigate(`/incharge/view/${row.incharge_id}`)
            }}
            >
              <i className="fa fa-eye text-primary"></i>
            </div>
          </div>
        ,
      },
    ];
  
    const navigate = useNavigate();
  
    React.useEffect(() => {
      getInchargeList("", "", 1);
    }, [pageNo, perPageRecords]);
  
    const inchargeHandlePageChange = (page) => {
      setPageNo(page);
    };
  
    const inchargeHandlePerRowsChange = async (newPerPage, page) => {
      setPerPageRecords(newPerPage);
    };
  
    function filterCompaniesList() {
      getInchargeList("", "", 1);
    }
  
  
    const resetFilter = () => {
      setFromDate(null)
      setToDate(null)
      setTxtSearch("")
      setFilterStatus(null)
      getInchargeList("", "", 0);
    };
  
    const searchData = (e) => {
      e.preventDefault();
  
      let isValidate = true;
      if (toDate || fromDate) {
        if (toDate && fromDate) {
          const dateFrom = new Date(fromDate);
          const dateTo = new Date(toDate);
          if (dateFrom > dateTo) {
            alertService.alert({
              type: AlertType.Error,
              message: "End date must be after start date",
            });
            isValidate = false;
          }
        }
      }
      if (isValidate) {
        getInchargeList("", "", 1);
      }
    };
  
    React.useEffect(() => {
      getInchargeList("", "", 1);
    }, [txtSearch]);
  
    async function getInchargeList(
      column = "",
      sortDirection = "",
      isFilter = 0
    ) {
      setDataTableLoading(true);
      setInchargeList({ isData: false, data: [] });
      const requestMetadata = await APICall(ApiRoutes.InchargeList, {
        association_type_term:Enums.UserRole.branch_manager,
        association_id : branch_id,
        page: pageNo,
        limit: perPageRecords,
        order: { column: column, dir: sortDirection },
        is_filter: isFilter,
        filter: {
          search_text: txtSearch,
          status : filterStatus,
          start_date:
            fromDate != null
              ? commonService.convertLocalToUTC(
                commonService.formatDate(fromDate) + " 00:00:00"
              )
              : null,
          end_date:
            toDate != null
              ? commonService.convertLocalToUTC(
                commonService.formatDate(toDate) + " 23:59:59"
              )
              : null,
        },
      });
  
      if (requestMetadata) {
        setInchargeList({ isData: true, data: requestMetadata.data.list });
  
        setInchargeTotalRows(requestMetadata.data.total);
        setDataTableLoading(false);
      }
      
    }
  
    const inchargeHandleSort = async (column, sortDirection) => {
      getInchargeList(column.sortField, sortDirection ,1);
    };
  
    const onSwitchToggle = async (value, item, index) => {
      const data = await APICall(ApiRoutes.InchargeStatusUpdate, {
        id: item.incharge_id,
        is_active: value ? "1" : "0",
      });
      if (data) {
        setInchargeList((prevState) => ({
          ...prevState,
          data: prevState.data.map((MapItem, MapIndex) => ({
            ...MapItem,
            is_active:
              MapIndex === index ? (value ? "1" : "0") : MapItem.is_active,
          })),
        }));
      }
    };
    const handleFilterStatus = (value)=>{
      if (value) {
        setFilterStatus(parseInt(value));
      } else {
        setFilterStatus(null);
      }
    }


  return ( <>
    <DataTable
      columns={companyTblColumns}
      data={inchargeList.data}
      progressPending={dataTableLoading}
      progressComponent={<NewLoader />}
      pagination
      paginationServer
      paginationTotalRows={inchargeTotalRows}
      onChangeRowsPerPage={inchargeHandlePerRowsChange}
      onChangePage={inchargeHandlePageChange}
      onSort={inchargeHandleSort}
      paginationPerPage={perPageRecords}
      actions={<>
        <div className="col-4">
          <InputText
            formGroupClass={"mb-0"}
            size={'12'}
            type={"text"}
            Label={false}
            placeholder={"Search..."}
            value={txtSearch}
            onChange={(value) => {
              if (value) {
                setTxtSearch(value)
              } else {
                setTxtSearch(value)
              }
            }}
          />
        </div>
      </>}
    />
    {/* )} */}
  </>)
}
