import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ApiRoutes, badgesEnum  ,leaveStatusEnum} from "../../constants";

import { Breadcrumb, Button, InputGroup } from "react-bootstrap";
import { Form, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { alertService, AlertType } from "../../services/alert.service";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import DataTable from "react-data-table-component";
import InputText from "../../components/form/inputText";
import DropDown from '../../components/common/DropDown';
import { Enums } from "../../constants/enums";
import NewLoader from "../../components/loader/NewLoader";

export default function Index() {
  const navigate = useNavigate();
  const [txtSearch, setTxtSearch] = useState("");
  const [leaves, setleavesList] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
  const [leavesFrom, setLeavesFrom] = useState(null);
  const [leavesTo, setLeavesTo] = useState(null);
  const [leavesTotalRows, setLeavesTotalRows] = useState(0);
  const [leaveList, setLeaveList] = useState({
    isData: false,
    data: [],
  });
  const [dataTableLoading, setDataTableLoading] = useState(false);
const [leaveStatus, setLeaveStatus] = useState(null);
const [contractList, setContractList] = useState([]);
const [selectedPropertyList, setSelectedPropertyList] = useState([]);


  React.useEffect(() => {
    getLeaveList( "" ,"" ,1);
  }, [txtSearch,pageNo, perPageRecords]);

  async function getLeaveList(
    column = "",
    sortDirection = "",
    isFilter = 0
  ) {
    setDataTableLoading(true);
    setLeaveList({ isData: false, data: [] });
    let newSelectedArray = [];
        selectedPropertyList.forEach((property_item)=>{
            newSelectedArray = [...newSelectedArray , property_item.value]
        })
    // console.log("🚀 --> getLeaveList --> txtSearch", txtSearch);
    const requestMetadata = await APICall(ApiRoutes.leavesList, {
      page: pageNo,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      is_filter: isFilter,
      filter: {
        search_text: txtSearch,
        start_date:
          leavesFrom != null
            ? commonService.convertLocalToUTC(
                commonService.formatDate(leavesFrom) + " 00:00:00"
              )
            : null,
        end_date:
          leavesTo != null
            ? commonService.convertLocalToUTC(
                commonService.formatDate(leavesTo) + " 23:59:59"
              )
            : null,
        leave_status: leaveStatus,
        contract_list: newSelectedArray
      },
    });

    if (requestMetadata) {
      setLeaveList({ isData: true, data: requestMetadata.data.list });

      setLeavesTotalRows(requestMetadata.data.total);
      setDataTableLoading(false);
    }
    
  }

  const handleSort = async (column, sortDirection) => {
    getLeaveList(column.sortField, sortDirection , 1);
  };

  const searchDateWise = (e) => {
    e.preventDefault();
    let isValidate = true;
    if (leavesFrom || leavesTo) {
      if (leavesFrom && leavesTo) {
        const dateFrom = new Date(leavesFrom);
        const dateTo = new Date(leavesTo);
        if (dateFrom > dateTo) {
          alertService.alert({
            type: AlertType.Error,
            message: "End date must be after start date",
          });
          isValidate = false;
        }
      }
    }
    if (isValidate) {
      getLeaveList("", "", 1);
      setLeavesTotalRows(1)
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const resetFilter = () => {
    setSelectedPropertyList([])
    setLeavesTo(null);
    setLeavesFrom(null);
    setLeaveStatus(null)
    setTxtSearch("")
    getLeaveList("", "", 0);
  };
  
  const fetchReviewContractList = async () => {
    let res = await APICall(ApiRoutes.contractOptions)
    if (res.status === 1) {
        setContractList(res.data.contract_list)
    }
}

useEffect(() => {
    fetchReviewContractList()
}, []);

  // React.useEffect(() => {
  //   getLeaveList("", "", 1);
  // }, [txtSearch]);
  const companyTblColumns = [
    {
      name: "Guard Name",
      selector: (row) => row.guard_name,
      sortable: true,
      sortField: "guard_name",
    },
    {
      name: "Contract Property",
      selector: (row) => row.property_name,
      sortable: true,
      width: "170px",
      sortField: "property_name",
    },
 
    {
      name: "Applied On",
      sortable: false,
      sortField: "created_at",
      cell: (row, index, column, id) =>
      commonService.convertUTCToLocal(row.created_at, 'DD-MM-yyyy, hh:mm A'),
    },
    {
      name: "Start Date",
      selector: (row) => row.start_date,
      sortable: true,
      sortField: "start_date",
      cell: (row, index, column, id) =>
        commonService.convertUTCToLocal(row.start_date),
    },
    {
      name: "End Date",
      selector: (row) => row.end_date,
      sortable: true,
      sortField: "end_date",
      cell: (row, index, column, id) =>
        commonService.convertUTCToLocal(row.end_date),
    },
    // {
    //   name: "Leave Type",
    //   selector: (row) => row.leave_type_title,
    //   sortable: true,
    //   sortField: "leave_type_title",
    // },
    {
      name: "Subject",
      width:"180px",
      selector: (row) => row.subject,
      sortable: true,
      sortField: "subject",
    },
    {
      name: "Status",
      sortable: false,
      sortField: "leave_status",
      cell: (row, index, column, id) => {
        return (
          <span className="justify-content-center align-items-center d-flex">
            {leaveStatusEnum[row.leave_status]}
          </span>
        );
      },
    },
   
    {
      name: "Action",
      selector: (row) => row.year,
      cell: (row, index, column, id) => (
        <div className="justify-content-center align-items-center d-flex">
          <Button
            style={{  fontSize: "0.7rem", padding: "0.3rem 1rem" }}
            variant="primary"
            type="button"
            onClick={() => {
              navigate(`/leaves/view/${row.leave_id}`, {
                state: {
                  id: row.quotation_id,
                },
              });
            }}
          >
            View
          </Button>
        </div>
      ),
    },
  ];
  const filterLeaveList = (value) => {
    // quotation_status
    if (value) {
      setLeaveStatus(value);
    } else {
      setLeaveStatus(null);
    }
  };
  return (
    <>
      <div className="row">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="breadcrumbs-top d-flex align-items-center">
              <h2 className="content-header-title float-start mb-0">
                Leave List
              </h2>
              <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/leaves">Setup</Breadcrumb.Item>
                <Breadcrumb.Item active>Leaves</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <div>
          <Card>
            <Card.Body className="p-3">
              <div className="col-12 row mb-3 mt-2">
              
                <div className="col-3">
                  <InputGroup>
                    <DatePicker
                      className="datepicker form-control"
                      selected={leavesFrom ? leavesFrom : ""}
                      dateFormat="MM-dd-yyyy"
                      placeholderText="Start Date"
                      onChange={(date) => {
                        if (date) {
                          setLeavesFrom(date);
                        } else {
                          setLeavesFrom(null);
                        }
                      }}
                    />
                  </InputGroup>
                </div>
                <div className="col-3">
                  <div>
                    <InputGroup>
                      <DatePicker
                        className="datepicker form-control"
                        selected={leavesTo ? leavesTo : ""}
                        dateFormat="MM-dd-yyyy"
                        placeholderText="End Date"
                        onChange={(date) => {
                          if (date) {
                            setLeavesTo(date);
                          } else {
                            setLeavesTo(null);
                          }
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
                <div className="col-6">
                                <DropDown
                                    value={selectedPropertyList}
                                    data={contractList}
                                    style={{ div: "col-12" }}
                                    isMulti={true}
                                    onChange={(value) =>
                                        setSelectedPropertyList(value)
                                    }
                                    placeholder="Select Contract/Property"
                                    required
                                // validationText={formInputsErrors.duration_term}
                                />
                            </div>
                <div className="col-3">
                  <Form.Select onChange={(e) => { filterLeaveList(e.target.value) }}>
                    <option value="" selected={!leaveStatus && true}>
                      Select Leave Status
                    </option>
                    <option
                      value="approved"
                      selected={leaveStatus == "approved" && true}
                    >
                      Approved
                    </option>
                    <option
                      value="rejected"
                      selected={leaveStatus == "rejected" && true}
                    >
                      Declined
                    </option>
                    <option
                      value="pending"
                      selected={leaveStatus == "pending" && true}
                    >
                      Leave Request
                    </option>
                  </Form.Select>
                </div>
                <div className="col-3 d-flex">
                  <div className="ms-2 me-2">
                    <Button
                      onClick={searchDateWise}
                      variant="outline-primary"
                      type="button"
                    >
                      <i className="fa fa-search"></i>
                    </Button>
                  </div>
                  <div className="ms-2 me-2">
                    <Button
                      onClick={resetFilter}
                      variant="outline-primary"
                      type="button"
                    >
                      <i className="fa fa-refresh"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <DataTable
        columns={companyTblColumns}
        data={leaveList.data}
        progressPending={dataTableLoading}
        progressComponent={<NewLoader />}
        pagination
        paginationServer
        paginationTotalRows={leavesTotalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        paginationPerPage={perPageRecords}
        actions={<>
          <div className="col-4">
            <InputText
              formGroupClass={"mb-0"}
              size={'12'}
              type={"text"}
              Label={false}
              placeholder={"Search..."}
              value={txtSearch}
              onChange={(value) => {
                if (value) {
                  setTxtSearch(value)
                } else {
                  setTxtSearch(value)
                }
              }}
            />
          </div>
        </>}
      />
    </>
  );
}
