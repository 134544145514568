import React, { useEffect, useState } from "react";
import { APICall } from "../../services/axiosService";
import { ApiRoutes } from "../../constants/apiroutes";
import { Button, Modal, Card, Form, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { commonService } from "../../services/common.service";
import {
  PatrollingSchedulesEnum,
  contractScheduleStatusEnum,
  Enums,
} from "../../constants";
import DatePicker from "react-datepicker";
import { alertService, AlertType } from "../../services/alert.service";
import GuardReplaceModal from "./GaurdReplace/GuardReplaceModal";
import NoRecordMsg from "../../components/table/NoRecordMsg";
import NewLoader from "../../components/loader/NewLoader";
import DailyPatrollingTableModal from "./DailyPatrollingTableModal";

export default function ContractSchedules({ contractId }) {
  const [dataTableLoading1, setDataTableLoading1] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [txtSearch, setTxtSearch] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [scheduleTotalRows1, setSheduleTotalRows1] = useState(0);
  const [perPageRecords, setPerPageRecords] = useState(
    Enums.DefaultPerPageLength
  );
  const [DailyPatrollingModalShow, setDailyPatrollingModalShow] =
  useState(false);
const [DailyPatrollingModalData, setDailyPatrollingModalData] = useState();
  const [scheduleStatus, setScheduleStatus] = useState(null);
  // const [scheduleFrom, setScheduleFrom] = useState(null);
  // const [scheduleTo, setScheduleTo] = useState(null);
  const [scheduleFrom, setScheduleFrom] = useState(new Date());
  const [scheduleTo, setScheduleTo] = useState(
    new Date().setDate(new Date().getDate() + 2)
  );
  const [schedules1, setSchedules1] = useState([]);

  const [guardRepModal, setGuardRepModal] = useState(false);

  const resetFilter = () => {
    setScheduleTo(null);
    setTxtSearch("");
    setScheduleFrom(null);
    setScheduleStatus(null);
    fetchGuardSchedules1("", "", 0);
  };

  const searchDateWise = () => {
    let isValidate = true;
    if (scheduleFrom || scheduleTo) {
      if (scheduleFrom && scheduleTo) {
        const dateFrom = new Date(scheduleFrom);
        const dateTo = new Date(scheduleTo);
        if (dateFrom > dateTo) {
          alertService.alert({
            type: AlertType.Error,
            message: "End date must be after start date",
          });
          isValidate = false;
        }
      }
    }
    if (isValidate) {
      fetchGuardSchedules1("", "", 1);
      setSheduleTotalRows1(1);
    }
  };

  const fetchGuardSchedules1 = async (
    column = "",
    sortDirection = "",
    isFilter = 0
  ) => {
    setDataTableLoading1(true);

    let res = await APICall(ApiRoutes.guardContraPatrollingSchedules, {
      contract_id: contractId,
      // guard_id: guard.guard_id,
      page: pageNo,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      is_filter: isFilter,
      filter: {
        search_text: txtSearch,
        start_date:
          scheduleFrom != null
            ? commonService.convertLocalToUTC(
                commonService.formatDate(scheduleFrom) + " 00:00:00"
              )
            : null,
        end_date:
          scheduleTo != null
            ? commonService.convertLocalToUTC(
                commonService.formatDate(scheduleTo) + " 23:59:59"
              )
            : null,
        schedule_status: scheduleStatus,
      },
    });

    if (res?.status == 1) {
      setSchedules1(res.data.list);
      setSheduleTotalRows1(res.data.total);
      setDataTableLoading1(false);
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };
  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handleSort = async (column, sortDirection) => {
    fetchGuardSchedules1(column.sortField, sortDirection, 1);
  };

  const filterSchedule = (value) => {
    if (value) {
      setScheduleStatus(value);
    } else {
      setScheduleStatus(null);
    }
  };

  const handleGaurdRepModal = () => {
    setGuardRepModal(true);
  };
  const columns = [
    {
      name: "Guard Name",
      selector: (row) => row.association_name,
      sortField: "association_name",
      sortable: true,
    },
    {
      name: "Schedule Date",
      selector: (row) =>
        commonService.convertUTCToLocal(row.schedule_date, "DD-MM-YYYY"),
      sortField: "schedule_date",
      sortable: true,
    },
    {
      name: "Day",
      selector: (row) =>
        commonService.convertUTCToLocal(row.schedule_date, "dddd"),
      sortField: "guard_email",
    },
    {
      name: "Start Time",
      selector: (row) =>
        commonService.convertUtcTimeToLocalTime(
          row.schedule_start_time,
          "DD-MM-YYYY HH:mm",
          "YYYY-MM-DD HH:mm:ss"
        ),
      sortField: "schedule_start_time",
      sortable: true,
    },
    {
      name: "End Time",
      selector: (row) =>
        commonService.convertUtcTimeToLocalTime(
          row.schedule_end_time,
          "DD-MM-YYYY HH:mm",
          "YYYY-MM-DD HH:mm:ss"
        ),
      sortField: "schedule_end_time",
      sortable: true,
    },
    {
      name: "Staff Type",
      selector: (row) => (
        <span title={Enums.GuardTypesDetails[row.staff_type]}>
          {Enums.GuardTypesDetails[row.staff_type]}
        </span>
      ),
      sortField: "guard_phone",
      width: "140px",
    },
    {
      name: "Leave",

      selector: (row) => (
        <div className="my-special-class">
          {row.is_leave ? (
            <i className="fa fa-check" aria-hidden="true"></i>
          ) : (
            ""
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Proxy",

      selector: (row) =>
        parseInt(row.is_proxy) == 1 ? (
          <i class="fa fa-flag" aria-hidden="true"></i>
        ) : (
          ""
        ),
      sortable: true,
    },
    {
      name: "Status",
      width: "180px",
      selector: (row) => row.schedule_status,
      sortField: "schedule_status",
      sortable: true,
      cell: (row, index, column, id) => {
        return (
          <span className="justify-content-center align-items-center d-flex">
            {contractScheduleStatusEnum[row.schedule_status]}
          </span>
        );
      },
    },
    {
      name: "Action",
      width: "80px",
      cell: (row, index, column, id) => {
        return (
          <>
            <span
              className="ms-3 cursor-pointer"
              onClick={() => {
                setDailyPatrollingModalShow(true);
                setDailyPatrollingModalData(row);
              }}
            >
              <i className="fa fa-eye text-primary"></i>
              {/* <svg
                width="20px"
                height="20px"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
              >
                <g fill="#6e6b7b" fill-rule="evenodd" clip-rule="evenodd">
                  <path d="M8 5a3 3 0 100 6 3 3 0 000-6zM6.5 8a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" />

                  <path d="M8 2C5.422 2 3.43 3.32 2.112 4.65A10.948 10.948 0 00.596 6.564c-.173.28-.31.536-.407.75a3 3 0 00-.122.31C.04 7.705 0 7.846 0 8s.041.296.067.375a3 3 0 00.122.31c.097.215.234.471.407.751.346.56.854 1.246 1.516 1.914C3.43 12.68 5.422 14 8 14s4.57-1.32 5.888-2.65a10.952 10.952 0 001.516-1.914c.173-.28.31-.536.407-.75.048-.107.09-.212.122-.31.026-.08.067-.221.067-.376s-.041-.296-.067-.375a2.978 2.978 0 00-.122-.31 6.777 6.777 0 00-.407-.751 10.952 10.952 0 00-1.516-1.914C12.57 3.32 10.578 2 8 2zM1.556 7.933a2.314 2.314 0 00-.03.067l.03.067c.065.145.17.344.316.58a9.45 9.45 0 001.306 1.647C4.332 11.458 5.964 12.5 8 12.5s3.668-1.042 4.822-2.206a9.45 9.45 0 001.306-1.646A5.336 5.336 0 0014.473 8a5.335 5.335 0 00-.346-.648 9.452 9.452 0 00-1.305-1.646C11.668 4.542 10.036 3.5 8 3.5S4.332 4.542 3.178 5.706a9.45 9.45 0 00-1.306 1.646 5.316 5.316 0 00-.316.58z" />
                </g>
              </svg> */}
            </span>
          </>
        );
      },
    },
  ];

  // React.useEffect(() => {
  //   fetchGuardSchedules1();
  // }, [pageNo, perPageRecords]);
  useEffect(() => {
    fetchGuardSchedules1("", "", 1);
  }, [txtSearch, pageNo, perPageRecords]);

  const TableExpander = (props) => {
    if (props.data.contract_sc_dtls.length) {
      return (
        <Card>
          <Card.Header className="p-2">Patrolling Schedules</Card.Header>
          <Card.Body className="p-2">
            <ul className="timeline ms-1">
              {props.data.contract_sc_dtls.map((contract_sc_dtl, index) => {
                return (
                  <li className="timeline-item" key={index}>
                    <span className="timeline-point timeline-point-indicator"></span>
                    <div className="timeline-event">
                      <div className="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                        <h6>
                          {commonService.convertUtcTimeToLocalTime(
                            contract_sc_dtl.checkpoint_start_time,
                            "DD-MM-YYYY HH:mm",
                            "YYYY-MM-DD HH:mm:ss"
                          )}{" "}
                          -{" "}
                          {commonService.convertUtcTimeToLocalTime(
                            contract_sc_dtl.checkpoint_end_time,
                            "DD-MM-YYYY HH:mm",
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </h6>
                        <span className="timeline-event-time h6 text-dark">
                          {/* {PatrollingSchedulesEnum[contract_sc_dtl.checkpoint_status]} */}
                          {
                            contract_sc_dtl.sc_checkpoint_history
                              .visited_checkpoints_count
                          }{" "}
                          /{" "}
                          {
                            contract_sc_dtl.sc_checkpoint_history
                              .total_checkpoints_count
                          }
                        </span>
                      </div>
                      <p className="login-time mb-1">
                        Patrolling schedule Summary
                      </p>
                      {contract_sc_dtl.sc_checkpoint_history.list.length ? (
                        <div className="mt-3">
                          <div>
                            <div
                              style={{ paddingRight: "0.5rem" }}
                              className="d-flex justify-content-between h6 mb-0 align-item-center"
                            >
                              <div className="h6">Checkpoints</div>
                              <div className="h6">Visited time</div>
                              <div className="h6">Visit Status</div>
                            </div>
                          </div>
                          <ul className="mt-2 p-0">
                            {contract_sc_dtl.sc_checkpoint_history.list.map(
                              (sc_checkpoint_history) => {
                                return (
                                  <li className="p-2 ">
                                    <div className="d-flex justify-content-between mb-0 align-item-center">
                                      <div className="h6">
                                        {sc_checkpoint_history.title}
                                      </div>
                                      <div className="h6">
                                        {sc_checkpoint_history.checkpoint_visited_time
                                          ? commonService.convertUTCToLocal(
                                              sc_checkpoint_history.checkpoint_visited_time,
                                              "DD/MM/YYYY hh:mm A"
                                            )
                                          : "-"}
                                      </div>
                                      <div>
                                        {!sc_checkpoint_history.checkpoint_visited_time
                                          ? PatrollingSchedulesEnum["pending"]
                                          : PatrollingSchedulesEnum[
                                              "completed"
                                            ]}
                                      </div>
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </ul>{" "}
                        </div>
                      ) : (
                        <div>No checkpoints found</div>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </Card.Body>
        </Card>
      );
    } else {
      return (
        <div className="text-center m-4 h6">No patrolling schedules found</div>
      );
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-3">
          <InputGroup>
            <DatePicker
              className="datepicker form-control"
              selected={scheduleFrom ? scheduleFrom : ""}
              dateFormat="MM-dd-yyyy"
              placeholderText="Start Date"
              onChange={(date) => {
                if (date) {
                  setScheduleFrom(date);
                } else {
                  setScheduleFrom(null);
                }
              }}
            />
          </InputGroup>
        </div>
        <div className="col-3">
          <div>
            <InputGroup>
              <DatePicker
                className="datepicker form-control"
                selected={scheduleTo ? scheduleTo : ""}
                dateFormat="MM-dd-yyyy"
                placeholderText="End Date"
                onChange={(date) => {
                  if (date) {
                    setScheduleTo(date);
                  } else {
                    setScheduleTo(null);
                  }
                }}
              />
            </InputGroup>
          </div>
        </div>
        <div className="col-3">
          <Form.Select
            onChange={(e) => {
              filterSchedule(e.target.value);
            }}
          >
            <option value="" selected={!scheduleStatus && true}>
              Select Schedule Status
            </option>
            <option
              value="completed"
              selected={scheduleStatus == "completed" && true}
            >
              Completed
            </option>
            <option
              value="in_progress"
              selected={scheduleStatus == "in_progress" && true}
            >
              In Progress
            </option>
            <option
              value="pending"
              selected={scheduleStatus == "pending" && true}
            >
              Pending
            </option>
          </Form.Select>
        </div>
        <div className="col-3">
          <div className="d-flex ">
            <div className="ms-2 me-2">
              <Button
                onClick={searchDateWise}
                variant="outline-primary"
                type="button"
              >
                <i className="fa fa-search"></i>
              </Button>
            </div>
            <div className="ms-">
              <Button
                onClick={resetFilter}
                variant="outline-primary"
                type="button"
              >
                <i className="fa fa-refresh"></i>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="my-tabel-main mt-4 contract-shedule-table-one">
        {/* {schedules.length ?  (<div className='row justify-content-end my-2'>
                <div className='col-3 text-end'>
                    <Button variant="primary" onClick={handleGaurdRepModal}>Guard Replacement</Button>
                </div>
            </div>) :""} */}
        <DataTable
          columns={columns}
          data={schedules1}
          progressPending={dataTableLoading1}
          progressComponent={<NewLoader />}
          pagination
          paginationServer
          // expandableRowsComponent={TableExpander}
          // expandableRows={true}
          expandableRowDisabled={(row) =>
            row.staff_type === Enums.GuardTypes.gate_guard
          }
          paginationTotalRows={scheduleTotalRows1}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSort={handleSort}
          paginationPerPage={perPageRecords}
          noDataComponent={<NoRecordMsg msg="No schedules yet!" />}
        />
      </div>

      <GuardReplaceModal
        fetchGuardSchedules={fetchGuardSchedules1}
        contractId={contractId}
        guardRepModal={guardRepModal}
        setGuardRepModal={setGuardRepModal}
      />
       {DailyPatrollingModalShow && (
        <DailyPatrollingTableModal
          data={DailyPatrollingModalData}
          show={DailyPatrollingModalShow}
          onHide={() => setDailyPatrollingModalShow(false)}
          flag='ContractShedule'
        />
      )}
    </>
  );
}
