import React, { useState } from "react";
import DefaultImg from "../../assets/Images/Icon/no_image.png";
import { commonHelper } from "../../helpers/commonHelper";
import { getBase64 } from "../../helpers/imageHelper";

export default function FileImageInput({
  selectedDocuments,
  title,
  input_ref_id,
  onChange,
  error,
  accept_file_type,
  style,
  validateExts,
  fileImageSrc,
  setFileImageSrc,
  portfolioExt = null,
  setPortfolioExt = null,
  permenentFileImage = null,
  permenentFileImageTitle = "View profile document",
  setCProfilePdfSize,
}) { 
  const validateFile = (ext) => {
    let isValidate = true;
    if (!validateExts.includes(ext)) {
      isValidate = false;
      setLocalError(
        `Please select ${validateExts.join(", ")} ${
          validateExts.length > 1 ? "files" : "file"
        }.`
      );
    } else {
      setLocalError("");
    }
    return isValidate;
  };
  const [localError, setLocalError] = useState("");

  return (
    <div className={`${style?.div ? style.div : "col-md-4 col-12"}`}>
      <div className="card border shadow-none mb-3 app-file-info">
        <div className="p-3 text-center">
          <div id="file_1_preview" className="image-fixed mb-3">
            <img
              // height={selectedDocuments.length ? '126' : '150'}
              className="obj_fit_img rounded"
              src={
                fileImageSrc
                  ? commonHelper.getExtBaseFile(
                      fileImageSrc,
                      portfolioExt
                        ? portfolioExt
                        : selectedDocuments.length
                        ? selectedDocuments[0].ext
                        : DefaultImg
                    )
                  : DefaultImg
              }
              alt=""
              height="150"
              width="150"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = DefaultImg;
              }}
            />
          </div>
          <div className="form-group add-new-file text-center mb-0">
            <label
              for={input_ref_id}
              className="btn btn-primary btn-block glow d-block add-file-btn text-capitalize"
            >
              Select File
            </label>
            <input
              type="file"
              className="d-none"
              id={input_ref_id}
              accept={`${
                accept_file_type != ""
                  ? accept_file_type
                  : ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
              }`}
              onChange={async (e) => {
                if (title === "Company Profile") {
                  setCProfilePdfSize(e.target.files[0]?.size);
                  if ((e.target.files[0]?.size / 1000000).toFixed(2) <= 15) {
                    setLocalError("");
                    getBase64(e.target.files[0])
                      .then((result) => {
                        onChange(result);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  } else {                    
                    setLocalError("Maximum allowed file size is 15MB.");
                    return;
                  }
                }

                let base64Arr = [];
                const files = Array.from(e.target.files);

                try {
                  let ext = files[0].name.split(".").pop();
                  const result = await getBase64(files[0]);
                  if (validateFile(ext)) {
                    base64Arr.push({
                      name: files[0].name,
                      base64: result,
                      ext: ext,
                    });
                    if (setPortfolioExt) {
                      setPortfolioExt(null);
                    }
                    setFileImageSrc(result);
                    onChange(base64Arr);
                  }
                } catch (err) {
                  console.log(err);
                }
              }}
            />
            <label className="form-label-input mt-3 text-break">{title}</label>
          </div>
        </div>
      </div>
      {permenentFileImage && (
        <div
          onClick={() => {
            if (portfolioExt === "pdf") {
              let pdfWindow = window.open("");
              pdfWindow.document.write(
                `<iframe width='100%' height='100%' src=${
                  permenentFileImage + "#toolbar=0&navpanes=0&scrollbar=0"
                }></iframe>`
              );
            } else {
              let aTag = document.createElement("a");
              aTag.setAttribute("href", permenentFileImage);
              aTag.setAttribute("target", "_blank");
              aTag.click();
            }
          }}
          className="text-center h6 text-primary cursor-pointer"
        >
          {permenentFileImageTitle}
        </div>
      )}
      {localError && <span className="error">{localError}</span>}
      {error && <span className="error mt-2">{error}</span>}
    </div>
  );
}
