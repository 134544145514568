import React, { useLayoutEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ApiRoutes, Enums } from "../../constants";
import { APICall } from "../../services/axiosService";
import { Breadcrumb, Card, Col, Button, Spinner, Row } from "react-bootstrap";
import { contractStatusEnum, leaveStatusEnum } from "../../constants";
import NoImage from "../../assets/Images/Icon/no_image.png";
import { commonService } from "../../services/common.service";
import { commonHelper } from "../../helpers/commonHelper";
import DefaultImg from "../../assets/Images/Icon/no_image.png";
import NewLoader from "../../components/loader/NewLoader";

export default function View() {
  const navigate = useNavigate();
  const params = useParams();
  const [incidentDetails, setIncidentDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchIncidentDetails = async (incident_id) => {
    setLoading(true);
    let res = await APICall(ApiRoutes.getIncidentDetails, {
      incident_id: incident_id,
    });

    if (res.status == 1) {
      setLoading(false);
      setIncidentDetails(res.data);
    } else {
      setLoading(false);
    }
  };

  const getLocation = (value) => {
    let str = (incidentDetails.address1
      ? incidentDetails.address1 + ", "
      : "") + (incidentDetails.address2
      ? incidentDetails.address2 + ", "
      : "" )+
        (incidentDetails.city ? incidentDetails.city + ", " : "") +
        (incidentDetails.state ? incidentDetails.state + ", " : "") +
        (incidentDetails.country ? incidentDetails.country + ", " : "") +
        (incidentDetails.postal_code ? incidentDetails.postal_code + ", " : "");
    str = str.trim();
    str = str.slice(0, -1);
    if (str.length && str && str !== "null") {
      return str;
    } else {
      return "-";
    }
  };

  const getGuardLocation = () => {
    let str = (incidentDetails.applicant_details.address1
      ? incidentDetails.applicant_details.address1 + ", "
      : "" )+ (incidentDetails.applicant_details.address2
      ? incidentDetails.applicant_details.address2 + ", "
      : "") +
        (incidentDetails.applicant_details.city
          ? incidentDetails.applicant_details.city + ", "
          : "") +
        (incidentDetails.applicant_details.state
          ? incidentDetails.applicant_details.state + ", "
          : "") +
        (incidentDetails.applicant_details.country
          ? incidentDetails.applicant_details.country + ", "
          : "") +
        (incidentDetails.applicant_details.postal_code
          ? incidentDetails.applicant_details.postal_code + ", "
          : "");
    str = str.trim();
    str = str.slice(0, -1);
    if (str.length && str && str !== "null") {
      return str;
    } else {
      return "-";
    }
  };

  useLayoutEffect(() => {
    if (params.incident_id) {
      fetchIncidentDetails(params.incident_id);
    }
  }, []);

  const renderDocumentCard = (item, index) => (
    <div className="added-cards col-lg-3">
      <div className="cardMaster rounded border p-3 mb-3">
        <div className="card-information col-12">
          <div className="align-items-center mb-2">
            <div id="profile_pic_1_preview" className="image-fixed">
              <img
                className="obj_fit_img rounded w-100"
                src={commonHelper.getExtBaseFile(
                  process.env.REACT_APP_ASSETS_URL + item.doc_url,
                  item.doc_url.split(".").pop()
                )}
                alt=""
                height="90"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = DefaultImg;
                }}
              />
            </div>
            <a
              href={process.env.REACT_APP_ASSETS_URL + item.doc_url}
              target="_blank"
              className=" d-flex justify-content-center mt-2 col-12 text-primary h6"
            >
              Preview
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  if (incidentDetails) {
    return (
      <>
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="breadcrumbs-top d-flex align-items-center">
              <h2 className="content-header-title float-start mb-0">
                Incident Details
              </h2>
              <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/incidents">Reports</Breadcrumb.Item>
                <Breadcrumb.Item href="/incidents">Incidents</Breadcrumb.Item>
                <Breadcrumb.Item active>Incident Details</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <Row className="contract-company-view">
          <div className="row">
            <div className="col-8">
              <Card>
                <Card.Header>
                  <h4 className="card-title">Incident Details</h4>
                </Card.Header>
                <Card.Body>
                  <dl className="mb-0 row">
                    <dt className="mb-2 col-md-4 col-sm-12 col-lg-3">
                      Incident Date :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {commonService.convertUTCToLocal(
                        incidentDetails.created_at,
                        "DD-MM-yyyy, hh:mm A"
                      )}
                    </dd>
                  </dl>

                  <dl className="mb-0 row">
                    <dt className="mb-2 col-md-4 col-sm-12 col-lg-3">
                      Description :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {incidentDetails.description}
                    </dd>
                  </dl>
                  {incidentDetails.incident_docs.length ? (
                    <div className="row mt-2">
                      <dl className="mb-0 mt-2 row">
                        <dt className="mb-2 col-md-12 col-sm-12 col-lg-12">
                          Incident Documents :
                        </dt>
                      </dl>
                      {incidentDetails.incident_docs.map(renderDocumentCard)}
                    </div>
                  ) : (
                    ""
                  )}
                </Card.Body>
              </Card>
              <Card>
                <Card.Header>
                  <h4 className="card-title">Contract Details</h4>
                  <div>
                    <Button
                      size="sm"
                      variant="primary me-3"
                      onClick={() => {
                        navigate("/contracts/view", {
                          state: { id: incidentDetails.contract_id },
                        });
                      }}
                    >
                      {" "}
                      View Contract{" "}
                    </Button>
                  </div>
                </Card.Header>
                <Card.Body>
                  <dl className="mb-0 row">
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Property Name :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {incidentDetails.property_name}
                    </dd>
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Property Manager :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {incidentDetails.client_name}
                    </dd>
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Service Hours :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {incidentDetails.contract_service_hours}
                    </dd>
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Start Date :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {commonService.convertUTCToLocal(
                        incidentDetails.start_date
                      )}
                    </dd>
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Expiry Date :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {commonService.convertUTCToLocal(
                        incidentDetails.end_date
                      )}
                    </dd>
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Status :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {incidentDetails.contract_status &&
                        contractStatusEnum[incidentDetails.contract_status]}
                    </dd>
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Property Location :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {getLocation()}
                    </dd>
                  </dl>
                </Card.Body>
              </Card>
            </div>
            <div className="col-4">
              <Card>
                <Card.Body>
                  <div className="company-profile-avatar">
                    <div className="d-flex align-items-center flex-column m-auto avatar rounded mt-3 mb-3 bg-light-primary">
                      <span className="w-100 h-100 avatar-content">
                        <img
                          className="w-100 h-100 obj-fit-cover"
                          src={
                            incidentDetails.applicant_details.profile_img
                              ? process.env.REACT_APP_ASSETS_URL +
                                incidentDetails.applicant_details.profile_img
                              : NoImage
                          }
                        ></img>
                      </span>
                    </div>
                    <div className="d-flex flex-column align-items-center text-center user-info">
                      <span className="mb-2 text-primary h4">
                        {incidentDetails.applicant_details.applicant_name}
                      </span>
                      <span className="text-capitalize badge bg-light-primary">
                        {incidentDetails.association_type_term ===
                          Enums.UserRole.guard && "#Guard"}
                        {incidentDetails.association_type_term ===
                          Enums.UserRole.incharge && "#Incharge"}
                      </span>
                    </div>
                  </div>
                  <div className="company-details mt-1">
                    <div className="left-field">
                      <i className="fa-solid fa-envelope text-primary me-2"></i>
                      {incidentDetails.applicant_details.applicant_email}
                    </div>
                  </div>
                  <div className="company-details mt-1">
                    <div className="left-field">
                      <i className="fa-solid fa-phone text-primary me-2"></i>
                      {incidentDetails.applicant_details.applicant_phone}
                    </div>
                  </div>
                  <div className="company-details mt-1">
                    <div className="left-field">
                      <i className="fa-solid fa-hashtag text-primary me-2"></i>
                      {incidentDetails.applicant_details.id_number}
                    </div>
                  </div>
                  <div className="company-details mt-1">
                    <div className="left-field">
                      <i className="fa-solid fa-map-marker text-primary me-2"></i>
                      {getGuardLocation()}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </Row>
      </>
    );
  } else {
    return <NewLoader/>;
  }
}
