import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiRoutes, PaymentReqStatusEnum } from "../../constants";
import { Breadcrumb, Button, InputGroup } from "react-bootstrap";
import { Form, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { alertService, AlertType } from "../../services/alert.service";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import DataTable from "react-data-table-component";
import InputText from "../../components/form/inputText";
import DropDown from '../../components/common/DropDown';
import { Enums } from "../../constants/enums";
import { useEffect } from "react";
import NewLoader from "../../components/loader/NewLoader";

export default function Index() {

    const navigate = useNavigate();
    const [txtSearch, setTxtSearch] = useState("");
    const [pageNo, setPageNo] = useState(1);
    const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
    const [payReqsFrom, setPayReqsFrom] = useState(null);
    const [payReqTo, setPayReqTo] = useState(null);
    const [payReqsTotalRows, setPayReqsTotalRows] = useState(0);
    const [payReqsList, setPayReqsList] = useState({
        isData: false,
        data: [],
    });
    const [dataTableLoading, setDataTableLoading] = useState(false);
    const [payReqStatus, setPayReqStatus] = useState(null);
    const [contractList, setContractList] = useState([]);
    const [selectedPropertyList, setSelectedPropertyList] = useState([]);

    
    React.useEffect(() => {
        getPayReqsList( "" ,"" ,1);
    }, [txtSearch,pageNo, perPageRecords]);

    async function getPayReqsList(
        column = "",
        sortDirection = "",
        isFilter = 0
    ) {
        setDataTableLoading(true);
        setPayReqsList({ isData: false, data: [] });
        let newSelectedArray = [];
        selectedPropertyList.forEach((property_item)=>{
            newSelectedArray = [...newSelectedArray , property_item.value]
        })
        const requestMetadata = await APICall(ApiRoutes.paymentReqList, {
            page: pageNo,
            limit: perPageRecords,
            order: { column: column, dir: sortDirection },
            is_filter: isFilter,
            filter: {
                search_text: txtSearch,
                start_date:
                    payReqsFrom != null
                        ? commonService.convertLocalToUTC(
                            commonService.formatDate(payReqsFrom) + " 00:00:00"
                        )
                        : null,
                end_date:
                    payReqTo != null
                        ? commonService.convertLocalToUTC(
                            commonService.formatDate(payReqTo) + " 23:59:59"
                        )
                        : null,
                payment_req_status: payReqStatus,
                contract_list: newSelectedArray
            },
        });
        if (requestMetadata) {
            setPayReqsList({ isData: true, data: requestMetadata.data.list });

            setPayReqsTotalRows(requestMetadata.data.total);
            setDataTableLoading(false);
        }
        
    }

    const handleSort = async (column, sortDirection) => {
        getPayReqsList(column.sortField, sortDirection , 1);
    };

    const searchDateWise = (e) => {
        e.preventDefault();
        let isValidate = true;
        if (payReqsFrom || payReqTo) {
            if (payReqsFrom && payReqTo) {
                const dateFrom = new Date(payReqsFrom);
                const dateTo = new Date(payReqTo);
                if (dateFrom > dateTo) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: "End date must be after start date",
                    });
                    isValidate = false;
                }
            }
        }
        if (isValidate) {
            getPayReqsList("", "", 1);
            setPayReqsTotalRows(1)
        }
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPageRecords(newPerPage);
    };

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const resetFilter = () => {
        setSelectedPropertyList([])
        setPayReqTo(null);
        setPayReqsFrom(null);
        setTxtSearch("")
        setPayReqStatus(null)
        getPayReqsList("", "", 0);
    };

    // React.useEffect(() => {
    //     getPayReqsList("", "", 1);
    //   }, [txtSearch]);

      const fetchReviewContractList = async () => {
        let res = await APICall(ApiRoutes.contractOptions)
        if (res.status === 1) {
            setContractList(res.data.contract_list)
        }
    }
    
    useEffect(() => {
        fetchReviewContractList()
    }, []);
    const companyTblColumns = [
        {
            name: "Contract Property",
            selector: (row) => row.property_name,
            sortable: true,
            width: "170px",
            sortField: "property_name",
        },
        {
            name: "Submitted By",
            selector: (row) => row.guard_name,
            sortable: true,
            sortField: "guard_name",
        },
        {
            name: "Requested On",
            sortable: false,
            sortField: "created_at",
            cell: (row, index, column, id) =>
                commonService.convertUTCToLocal(row.created_at, 'DD-MM-yyyy, hh:mm A'),
        },
        {
            name: "Requested Amount",
            selector: (row) =>  row.request_amount ? parseFloat(row.request_amount).toFixed(2) : "",
            sortable: true,
            sortField: "request_amount",
        },
        {
            name: "Paid Amount",
            selector: (row) => row.paid_amount ? parseFloat(row.paid_amount).toFixed(2) : "",
            sortable: true,
            sortField: "paid_amount",
        },
        {
            name: "Status",
            sortable: false,
            sortField: "payment_req_status",
            cell: (row, index, column, id) => {
                return (
                    <span className="justify-content-center align-items-center d-flex">
                        {PaymentReqStatusEnum[row.payment_req_status]}
                    </span>
                );
            },
        },
    
        {
            name: "Action",
            selector: (row) => row.year,
            cell: (row, index, column, id) => (
                <div className="justify-content-center align-items-center d-flex">
                    <Button
                        style={{ fontSize: "0.7rem", padding: "0.3rem 1rem" }}
                        variant="primary"
                        type="button"
                        onClick={() => {
                            navigate(`/payment_requests/view/${row.payment_request_id}`, {
                                state: {
                                    id: row.quotation_id,
                                },
                            });
                        }}
                    >
                        View
                    </Button>
                </div>
            ),
        },
    ];
    const filterPayReqsList = (value) => {
        // quotation_status
        if (value) {
            setPayReqStatus(value);
        } else {
            setPayReqStatus(null);
        }
    };

    return (
        <>
            {" "}
            <div className="row">
                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="breadcrumbs-top d-flex align-items-center">
                            <h2 className="content-header-title float-start mb-0">
                            Advance Salary List
                            </h2>
                            <Breadcrumb className="breadcrumb-chevron">
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item href="/payment_requests">Setup</Breadcrumb.Item>
                                <Breadcrumb.Item active>Advance Salary</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                </div>
                <div>
                    <Card>
                        <Card.Body className="p-3">
                            <div className="col-12 row mb-3 mt-2">
                                
                                <div className="col-3">
                                    <InputGroup>
                                        <DatePicker
                                            className="datepicker form-control"
                                            selected={payReqsFrom ? payReqsFrom : ""}
                                            dateFormat="MM-dd-yyyy"
                                            placeholderText="Start Date"
                                            onChange={(date) => {
                                                if (date) {
                                                    setPayReqsFrom(date);
                                                } else {
                                                    setPayReqsFrom(null);
                                                }
                                            }}
                                        />
                                    </InputGroup>
                                </div>
                                <div className="col-3">
                                    <div>
                                        <InputGroup>
                                            <DatePicker
                                                className="datepicker form-control"
                                                selected={payReqTo ? payReqTo : ""}
                                                dateFormat="MM-dd-yyyy"
                                                placeholderText="End Date"
                                                onChange={(date) => {
                                                    if (date) {
                                                        setPayReqTo(date);
                                                    } else {
                                                        setPayReqTo(null);
                                                    }
                                                }}
                                            />
                                        </InputGroup>
                                    </div>
                                </div>
                                <div className="col-6">
                                <DropDown
                                    value={selectedPropertyList}
                                    data={contractList}
                                    style={{ div: "col-12" }}
                                    isMulti={true}
                                    onChange={(value) =>
                                        setSelectedPropertyList(value)
                                    }
                                    placeholder="Select Contract/Property"
                                    required
                                // validationText={formInputsErrors.duration_term}
                                />
                            </div>
                                <div className="col-3">
                                    <Form.Select onChange={(e) => { filterPayReqsList(e.target.value) }}>
                                        <option value="" selected={!payReqStatus && true}>
                                            Select Advance Salary Status
                                        </option>
                                        <option
                                            value="approved"
                                            selected={payReqStatus == "approved" && true}
                                        >
                                            Approved
                                        </option>
                                        <option
                                            value="rejected"
                                            selected={payReqStatus == "rejected" && true}
                                        >
                                            Declined
                                        </option>
                                        <option
                                            value="pending"
                                            selected={payReqStatus == "pending" && true}
                                        >
                                            Payment Request
                                        </option>
                                    </Form.Select>
                                </div>
                               
                                <div className="col-3 d-flex">
                                    <div className="ms-2 me-2">
                                        <Button
                                            onClick={searchDateWise}
                                            variant="outline-primary"
                                            type="button"
                                        >
                                            <i className="fa fa-search"></i>
                                        </Button>
                                    </div>
                                    <div className="ms-2 me-2">
                                        <Button
                                            onClick={resetFilter}
                                            variant="outline-primary"
                                            type="button"
                                        >
                                            <i className="fa fa-refresh"></i>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <DataTable
                columns={companyTblColumns}
                data={payReqsList.data}
                progressPending={dataTableLoading}
                progressComponent={<NewLoader />}
                pagination
                paginationServer
                paginationTotalRows={payReqsTotalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onSort={handleSort}
                paginationPerPage={perPageRecords}
                actions={<>
                    <div className="col-4">
                      <InputText
                        formGroupClass={"mb-0"}
                        size={'12'}
                        type={"text"}
                        Label={false}
                        placeholder={"Search..."}
                        value={txtSearch}
                        onChange={(value) => {
                          if (value) {
                            setTxtSearch(value)
                          } else {
                            setTxtSearch(value)
                          }
                        }}
                      />
                    </div>
                  </>}
            />
        </>
    )
}
