import React, { useState } from "react";
import { Breadcrumb, Button, InputGroup, Form, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import Switch from "react-switch";

// import FilterComponent from "../../components/common/FilterComponent";
import { ApiRoutes } from "../../constants/apiroutes";
import { Enums } from "../../constants/enums";
// import NoDataFound from "../../components/common/NoDataFound";
import getUserAccess from "../../helpers/userAccess";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import { alertService, AlertType } from "../../services/alert.service";
import InputText from "../../components/form/inputText";
import DatePicker from "react-datepicker";
import { BranchStatusEnum } from "../../constants/badgeEnum";
import BranchStatusPopup from "./BranchStatusPopup";
import Swal from "sweetalert2";
import NewLoader from "../../components/loader/NewLoader";


function Index() {
  const navigate = useNavigate();
  const [txtSearch, setTxtSearch] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [branchTotalRows, setBranchTotalRows] = useState(0);
  const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
  const [branchList, setBranchList] = useState({
    isData: false,
    data: [],
  });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);


  const [branchStatusPopup, setBranchStatusPopup] = useState(false);
  const [branchStatusId, setBranchStatusId] = useState(null);


  const userData = JSON.parse(commonService.getItem("user"));

  const RoleRights = getUserAccess(Enums.UserRights.branch);

  const userType = userData.association_type_term;




  const handleShowBranchStatusPopUp = (branch_id) => {
    setBranchStatusPopup(true)
    setBranchStatusId(branch_id)
  }


  const handleDelete = async (e, branch_id) => {

    e.preventDefault();

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      let res = await APICall(ApiRoutes.BranchDelete, {
        branch_id: branch_id
      })
      if (res.status === 1) {
        
        alertService.alert({
          type: AlertType.Success,
          message: res.message,
        });
        getBranchList("", "", 1);
        setBranchTotalRows(1)
      }
    }
  }

  const getBranchTblColumns = () => {
    const branchTblColumns = [
      {
        name: "Branch Manager",
        
        selector: (row) =><div className="cursor-pointer text-primary" onClick={()=>{
          navigate(`/branch/view/${row.branch_id}`)
        }}> {row.branch_name}</div>,
        sortable: true,
        sortField: "branch_name",
      },
      {
        name: "Company Name",
        selector: (row) => <div className="cursor-pointer text-primary" onClick={()=>{
          navigate(`/company/view/${row.company_id}`)
        }}> {row.company_name}</div>,
        sortable: true,
        sortField: "company_name",
      },
      {
        name: "Email",
        selector: (row) => row.branch_email,
        sortable: true,
        sortField: "Email",
      },
      {
        name: "Phone",
        selector: (row) => row.branch_phone,
        sortable: true,
        sortField: "branch_phone",
      },
      // {
      //   name: "Registration No",
      //   selector: (row) => row.branch_register_no,
      //   sortable: true,
      //   sortField: "branch_register_no",
      // },
      {
        name: "Total Guards",
        selector: (row) => row.total_guards_count,
        sortable: true,
        sortField: "total_guards_count",
      },
      {
        name: "Total Incharge",
        selector: (row) => row.total_incharges_count,
        sortable: true,
        sortField: "total_incharges_count",
      },
      {
        name: "Request",
        selector: (row) => <div className="d-flex align-items-center">
          {BranchStatusEnum[row.branch_status]}
          {(row.branch_status == Enums.BranchStatus.pending || row.branch_status == Enums.BranchStatus.rejected ) && userType === Enums.UserRole.admin ? <span className="ms-2 cursor-pointer h6 m-0"><i onClick={(e) => {
            e.preventDefault()
            handleShowBranchStatusPopUp(row.branch_id)

          }} className="fa fa-edit"></i></span> : ""}

        </div>,
        // omit: userType === Enums.UserRole.admin ? false : true ,
        sortable: false,
      },
      {
        name: "Status",
        selector: (row) => row.is_active,
        sortable: true,
        sortField: "is_active",
        cell: (row, index, column, id) => {
          return (
            <span className="justify-content-center align-items-center d-flex">
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                onChange={(value) => onSwitchToggle(value, row, index)}
                checked={row.is_active === "1" ? true : false}
                height={20}
                width={40}
                disabled={!RoleRights.is_update}
                onColor="#263A96"
              />
            </span>
          );
        },
      },
      {
        name: "Total Contracts",
        selector: (row) => row.contract_counts,
        sortable: true,
        sortField: "contract_counts",
      },
      {
        name: "Start Date",
        selector: (row) => row.start_date,
        sortable: true,
        sortField: "start_date",
        omit: userType === Enums.UserRole.admin ? false : true || userType === Enums.UserRole.company ? false : true,
        cell: (row, index, column, id) =>
          row.start_date ? commonService.convertUTCToLocal(row.start_date) : "",
      },
      {
        name: "Expiry Date",
        selector: (row) => row.end_date,
        sortable: true,
        omit: userType === Enums.UserRole.admin ? false : true || userType === Enums.UserRole.company ? false : true,
        sortField: "end_date",
        cell: (row, index, column, id) =>
          row.end_date ? commonService.convertUTCToLocal(row.end_date) : "",
      },
      // {
      //   name: "Created On",
      //   selector: (row) => row.created_at,
      //   sortable: true,
      //   sortField: "created_at",
      //   cell: (row, index, column, id) =>
      //     commonService.convertUTCToLocal(row.created_at),
      // },
      {
        name: "Action",
        selector: (row) => row.year,
        cell: (row, index, column, id) =>

          <div className="justify-content-center align-items-center d-flex">
            {RoleRights.is_update === 1 && (<div className="me-2 cursor-pointer"
              onClick={() => {
                history("edit", {
                  state: {
                    id: row.branch_id,
                  },
                });
              }}
            >
              <i className="fa fa-edit text-primary"></i>
            </div>
            )}
            <div className="me-2 cursor-pointer" onClick={() => {
              navigate(`/branch/view/${row.branch_id}`)
            }}
            >
              <i className="fa fa-eye text-primary"></i>
            </div>
            {RoleRights.is_delete === 1 && (<div className="cursor-pointer"
                            onClick={(e) => {
                               handleDelete(e , row.branch_id)
                            }}
                        >
                            <i className="fa fa-trash text-danger"></i>
                        </div>)}
          </div>
        ,
      },
    ];

    if (userData.association_type_term !== "admin") {
      const newTblData = branchTblColumns.filter(
        (filterItem) => filterItem.sortField !== "company_name"
      );
      return newTblData;
    }

    return branchTblColumns;
  };

  const history = useNavigate();

  React.useEffect(() => {
    getBranchList("", "", 1);
  }, [txtSearch,pageNo, perPageRecords]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };

  function filterCompaniesList() {
    getBranchList("", "", 1);
  }
  const resetFilter = () => {
    setFromDate(null)
    setToDate(null)
    setTxtSearch("")
    setFilterStatus(null)
    getBranchList("", "", 0);
  };

  const searchData = (e) => {
    e.preventDefault();

    let isValidate = true;
    if (toDate || fromDate) {
      if (toDate && fromDate) {
        const dateFrom = new Date(fromDate);
        const dateTo = new Date(toDate);
        if (dateFrom > dateTo) {
          alertService.alert({
            type: AlertType.Error,
            message: "End date must be after start date",
          });
          isValidate = false;
        }
      }
    }
    if (isValidate) {
      getBranchList("", "", 1);
      setBranchTotalRows(1)
    }
  };

  // React.useEffect(() => {
  //   getBranchList("", "", 1);
  // }, [txtSearch]);

  async function getBranchList(column = "", sortDirection = "", isFilter = 0) {
    setDataTableLoading(true);
    setBranchList({ isData: false, data: [] });
    const requestMetadata = await APICall(ApiRoutes.BranchList, {
      page: pageNo,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      is_filter: isFilter,
      filter: {
        search_text: txtSearch,
        status: filterStatus,
        start_date:
          fromDate != null
            ? commonService.convertLocalToUTC(
              commonService.formatDate(fromDate) + " 00:00:00"
            )
            : null,
        end_date:
          toDate != null
            ? commonService.convertLocalToUTC(
              commonService.formatDate(toDate) + " 23:59:59"
            )
            : null,
      },
    });

    if (requestMetadata) {
      setBranchList({ isData: true, data: requestMetadata.data.list });

      setBranchTotalRows(requestMetadata.data.total);
      setDataTableLoading(false);
    }
  }

  const handleSort = async (column, sortDirection) => {
    getBranchList(column.sortField, sortDirection, 1);
  };

  const onSwitchToggle = async (value, item, index) => {
    const data = await APICall(ApiRoutes.BranchStatusUpdate, {
      id: item.branch_id,
      is_active: value ? "1" : "0",
    });
    if (data) {
      setBranchList((prevState) => ({
        ...prevState,
        data: prevState.data.map((MapItem, MapIndex) => ({
          ...MapItem,
          is_active:
            MapIndex === index ? (value ? "1" : "0") : MapItem.is_active,
        })),
      }));
    }
  };

  const handleFilterStatus = (value) => {
    if (value) {
      setFilterStatus(parseInt(value));
    } else {
      setFilterStatus(null);
    }
  }

  return (
    <>
      <div className="row">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="breadcrumbs-top d-flex align-items-center">
              <h2 className="content-header-title float-start mb-0">
                Branch Manager List
              </h2>
              <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/branch">Setup</Breadcrumb.Item>
                <Breadcrumb.Item active>Branch Manager List</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>

        <div className="col-12 d-flex align-items-center justify-content-end mb-3 mt-2">
        {RoleRights.is_create === 1 &&(
            <Link to="/branch/create">
              <Button className="btn-15" variant="primary">
                <i className="fa-regular fa-plus"></i> Add Branch Manager
              </Button>
            </Link>
          )}
        </div>
      </div>

      <div>
        <Card>
          <Card.Body className="p-3">
            <div className="row">

              <div className="col-3">
                <InputGroup>
                  <DatePicker
                    className="datepicker form-control"
                    selected={fromDate ? fromDate : ""}
                    dateFormat="MM-dd-yyyy"
                    placeholderText="Start Date"
                    onChange={(date) => {
                      if (date) {
                        setFromDate(date);
                      } else {
                        setFromDate(null);
                      }
                    }}
                  />
                </InputGroup>
              </div>
              <div className="col-3">
                <div>
                  <InputGroup>
                    <DatePicker
                      className="datepicker form-control"
                      selected={toDate ? toDate : ""}
                      dateFormat="MM-dd-yyyy"
                      placeholderText="End Date"
                      onChange={(date) => {
                        if (date) {
                          setToDate(date);
                        } else {
                          setToDate(null);
                        }
                      }}
                    />
                  </InputGroup>
                </div>

              </div>
              <div className="col-3">
                <Form.Select onChange={(e) => { handleFilterStatus(e.target.value) }}>
                  <option value="" selected={filterStatus == null && true}>
                    Select Branch Status
                  </option>
                  <option value="1" selected={filterStatus == 1 && true}>
                    Active
                  </option>
                  <option value="0" selected={filterStatus == 0 && true}>
                    Inactive
                  </option>

                </Form.Select>
              </div>
              <div className="col-3">
                <div className="d-flex">
                  <div className="me-2">
                    <Button
                      onClick={searchData}
                      variant="outline-primary"
                      type="button"
                    >
                      <i className="fa fa-search"></i>
                    </Button>
                  </div>
                  <div className="ms-">
                    <Button
                      onClick={resetFilter}
                      variant="outline-primary"
                      type="button"
                    >
                      <i className="fa fa-refresh"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

          </Card.Body>
        </Card>
      </div>

      {/* <FilterComponent
        searchText={txtSearch}
        PHSearchText={DisplayText.SearchText}
        PHDatepickerFrom={DisplayText.DatepickerFrom}
        PHDatepickerTo={DisplayText.DatepickerTo}
        startDate={filterStartDate}
        endDate={filterEndDate}
        onSearchTextChange={(value) => setTxtSearch(value)}
        onStartDateChange={(value) => setFilterStartDate(value)}
        onEndDateChange={(value) => setFilterEndDate(value)}
        onSearchClick={() => filterCompaniesList()}
      />

      {branchList.data.length === 0 ? (
        <div className="event-details">
          <NoDataFound fontsize={"150px"} />
        </div>
      ) : ( */}
      <DataTable
        columns={getBranchTblColumns()}
        data={branchList.data}
        progressPending={dataTableLoading}
        progressComponent={<NewLoader/>}
        pagination
        paginationServer
        paginationTotalRows={branchTotalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        paginationPerPage={perPageRecords}
        actions={<>
          <div className="col-4">
            <InputText
              formGroupClass={"mb-0"}
              size={'12'}
              type={"text"}
              Label={false}
              placeholder={"Search..."}
              value={txtSearch}
              onChange={(value) => {
                if (value) {
                  setTxtSearch(value)
                } else {
                  setTxtSearch(value)
                }
              }}
            />
          </div>
        </>}
      />
      {/* )} */}

      {branchStatusPopup &&
        <BranchStatusPopup
        getBranchList={getBranchList}
          branchStatusPopup={branchStatusPopup}
          setBranchStatusPopup={setBranchStatusPopup}
          branchStatusId={branchStatusId}
          setBranchStatusId={setBranchStatusId}
        />
      }

    </>
  );
}

export default Index;
