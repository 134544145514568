const APIBasePath = process.env.REACT_APP_API_URL;

export const ApiRoutes = {
  logout: APIBasePath + "logout",
  fetchPolicy: APIBasePath + 'app_setting',
  SignUp: APIBasePath + "client/register",
  UserRights: APIBasePath + "get_user_rights",
  PaymentModeList: APIBasePath + "options/payment_duration",
  CompanyDropdownList: APIBasePath + "options/company",
  PropertyDropdownList: APIBasePath + "options/property",
  fetchGuardList: APIBasePath + 'options/get_guard_list',
  getGuidelines: APIBasePath + 'get_guidelines',
  getTimezoneList: APIBasePath + 'options/get_timezone_list',

  CompanySave: APIBasePath + "company/save",
  CompanyList: APIBasePath + "company/list",
  CompanyStatusUpdate: APIBasePath + "company/update_status",
  CompanyEditDetails: APIBasePath + "company/get_details",
  CompanyDelete: APIBasePath + "company/delete_company",

  BranchSave: APIBasePath + "branch/save",
  BranchList: APIBasePath + "branch/list",
  BranchStatusUpdate: APIBasePath + "branch/update_status",
  BranchEditDetails: APIBasePath + "branch/get_details",
  BranchAcceptReject: APIBasePath + "branch/active_or_reject_branch_status",
  BranchDelete: APIBasePath + "branch/delete_branch",


  GuardSave: APIBasePath + "guard/save",
  GuardList: APIBasePath + "guard/list",
  GuardStatusUpdate: APIBasePath + "guard/update_status",
  GuardEditDetails: APIBasePath + "guard/get_details",
  BranchDropDownList: APIBasePath + "options/company_branch_list",
  acceptGuardStatus: APIBasePath + "guard/accept_guard_status",
  acceptInchargeStatus: APIBasePath + "incharge/accept_incharge_status",
  GuardDelete: APIBasePath + "guard/delete_guard",

  InchargeSave: APIBasePath + "incharge/save",
  InchargeList: APIBasePath + "incharge/list",
  InchargeStatusUpdate: APIBasePath + "incharge/update_status",
  InchargeEditDetails: APIBasePath + "incharge/get_details",
  InchargeDelete: APIBasePath + "incharge/delete_incharge",

  ProfileDetails: APIBasePath + "get_profile_details",
  UpdateProfileDetails: APIBasePath + "update_profile_details",

  UserSave: APIBasePath + "client/contact/save",
  UserList: APIBasePath + "client/contact/list",
  UserStatusUpdate: APIBasePath + "client/contact/update_status",
  UserEditDetails: APIBasePath + "client/contact/get_details",
  UserDelete: APIBasePath + "client/contact/delete",
  ClientDelete: APIBasePath + "client/delete_client",

  PropertySave: APIBasePath + "property/save",
  PropertyList: APIBasePath + "property/list",
  PropertyStatusUpdate: APIBasePath + "property/update_status",
  PropertyEditDetails: APIBasePath + "property/get_details",

  QuotationList: APIBasePath + "quotation/list",
  QuotationDuration: APIBasePath + "options/quotation_duration",
  QuotationServiceHours: APIBasePath + "options/quotation_service_hours",
  QuotationCompanyList: APIBasePath + "options/company_and_branches_all",
  QuotationSave: APIBasePath + "quotation/save",
  QuotationDetails: APIBasePath + "quotation/get_details",
  QuotationCancelCompany: APIBasePath + "quotation/company/cancel",
  QuotationAddCompany: APIBasePath + "quotation/company/add",
  QuotationUploadDocs: APIBasePath + "quotation/upload_documents",
  QuotationAcceptReject: APIBasePath + "quotation/company/action",
  QuotationClientConfirm: APIBasePath + "quotation/company/confirm",

  ContractSave: APIBasePath + "contract/save",
  ContractList: APIBasePath + "contract/list",
  ContractDetails: APIBasePath + "contract/get_details",
  CreateSosRequest: APIBasePath + "contract/create_sos_request",

  //Code Added By Kushal
  //Notification

  unreadNotifications: APIBasePath + "get_unread_notifications",
  allNotifications: APIBasePath + "get_notifications",
  markAsReadNotifications: APIBasePath + "mark_as_read_notifications",
  changePassword: APIBasePath + "change_password",
  sendResetPassLink: APIBasePath + "send_reset_pass_link",
  resetPassword: APIBasePath + "reset_password",
  checkResetToken: APIBasePath + "check_reset_token",
  acknowledgeSosRequest: APIBasePath + "contract/acknowledge_sos_request",
  //Scheduling
  weekDays: APIBasePath + "options/week_days",
  saveSchedules: APIBasePath + "contract/save/schedules",
  guardContraSchedules: APIBasePath + "contract/guard/schedules",
  guardContraPatrollingSchedules: APIBasePath + "contract/guard/patrolling_schedules",
  guardContraSchedulesById: APIBasePath + "contract/guard/schedulesById",
  // demoPdf: APIBasePath + "contract/neel_demo_pdf",
  generateDailyPatrollingPdf: APIBasePath + "contract/generate_daily_patrolling_pdf",
  checkpointQRcodePdf: APIBasePath + "contract/checkpoints_pdf",
  
  //AddGuardAndincharge
  guardOptions: APIBasePath + "contract/guard/options",
  inchargeOptions: APIBasePath + "contract/incharge/options",
  addStaffMember: APIBasePath + "contract/add/staff_member",
  addCheckPoint: APIBasePath + "contract/add/checkpoint",
  deleteStaffMember: APIBasePath + "contract/delete/staff_member",
  //Contract Comapny Profile
  contractCompanyProfile: APIBasePath + "contract/company/profile",
  contractBranchProfile: APIBasePath + "contract/branch/profile",
  //Leave View
  leaveDetails: APIBasePath + "leave/details",
  leaveAction: APIBasePath + "leave/action",

  leaveGuardReplaceList: APIBasePath + "leave/guard_replaced_list",
  // Payment Req View 
  paymentReqDetails: APIBasePath + "payment_req/details",
  paymentReqAction: APIBasePath + "payment_req/action",
  paymentReqList: APIBasePath + "payment_req/list",
  // Client View Contract List
  ClientContractList: APIBasePath + "client/contract_details",
  // Dashboard Details
  dashboardDetails: APIBasePath + "dashboard/get_dashboard_details",
  //Settings
  getWebSettings: APIBasePath + "settings/get_web_setting",
  updateWebSettings: APIBasePath + "settings/update_web_setting",
  getAppSettings: APIBasePath + "settings/get_app_setting",
  updateAppSettings: APIBasePath + "settings/update_app_setting",
  fetchPropertyCategories: APIBasePath + "settings/get_property_categories",
  PropertyCategoryStatusUpdate: APIBasePath + "settings/update_property_category_status",
  addPropertyCategory: APIBasePath + "settings/add_property_category",
  fetchPropertyCategoryTerm: APIBasePath + "settings/get_property_category",
  updatePropertyCategory: APIBasePath + "settings/update_property_category",
  //Complaints
  getComplaints: APIBasePath + "ticket_request/list",
  complainDetails: APIBasePath + "ticket_request/details",
  complainAction: APIBasePath + "ticket_request/action",
  //Visitors
  getVisitors: APIBasePath + "visitor/list",
  visitorDetails: APIBasePath + "visitor/details",
  getVisitorsClients: APIBasePath + "visitor/client/list",
  getVisitorsProperties: APIBasePath + "visitor/contract/list",
  //Contract Invoice
  addInvoice: APIBasePath + "contract/add/invoice",
  getInvoices: APIBasePath + "invoice/report/list",
  getInvoiceClients: APIBasePath + "invoice/client/list",
  getInvoiceProperties: APIBasePath + "invoice/contract/list",
  //Incidents
  getIncident: APIBasePath + "incident/list",
  getIncidentClients: APIBasePath + "incident/client/list",
  getIncidentProperties: APIBasePath + "incident/contract/list",
  getIncidentDetails: APIBasePath + "incident/details",
  contractStatusUpdate: APIBasePath + 'contract/update_status',
  clientCompanyBranchDropDown: APIBasePath + 'options/client_company_branch_list',

  liveLocationStatusUpdate: APIBasePath + 'contract/guard/live_location_update',

  fetchReviews: APIBasePath + 'review/list',
  fetchRating: APIBasePath + 'review/get_details',
  reviewStatusUpdate: APIBasePath + 'review/status_update',
  addUpdateReview: APIBasePath + 'review/add_update_review',
  fetchReviewContractList: APIBasePath + 'review/contract/list',
  fetchAssociationReviews: APIBasePath + 'review/list_by_user',


  //Code Added By Kushal End

  // Code added by Ravi
  leavesList: APIBasePath + "leave/list",
  // Code ended by Ravi
  ClientList: APIBasePath + "client/list",
  ClientDetails: APIBasePath + "client/get_details",


  //View pages
  viewContractsList: APIBasePath + "contract/list_by_user",


  guardAttendanceReport: APIBasePath + "report/gaurd/attendance/list",
  contractOptions: APIBasePath + "options/get_contract_options_list",


};
