import React from 'react'
import { useState } from 'react'
import { Modal, Form, Row, Spinner, Button } from 'react-bootstrap'
import InputText from '../../../components/form/inputText'
import { ApiRoutes, Enums } from '../../../constants';
import { APICall } from '../../../services/axiosService';


export default function AddCategory({ showAddCategory, setShowAddCategory ,fetchPropertyCategories}) {
    
    const [loading, setLoading] = useState(false);
    const [formInputs, setFormInput] = useState({
        term_category_code : Enums.TermCategoryCode.property_category,
        term_code: "",
        term: "",
        term_detail: ""
    });

    const [formInputEr, setFormInputEr] = useState({
        term_code: "",
        term: "",
        term_detail: ""
    });

    const validateMsg = {
        term_code: "Property Type Code is required.",
        term: "Property Type Name is required.",
        term_detail: "Property Type Details is required."
    }

    const resetFields =()=>{
        setFormInputEr({
            term_code: "",
            term: "",
            term_detail: ""
        })
        setFormInput({
            term_category_code : Enums.TermCategoryCode.property_category,
            term_code: "",
            term: "",
            term_detail: ""
        })
    }
    const closePopup = () => {
        setShowAddCategory(false)
        resetFields()
    }

    const validateForm = () => {
        let isValid = true;
        let errorJson = {};
        Object.keys(formInputEr).forEach(key_item => {
            if (formInputs[key_item] === "") {
                errorJson = {
                    ...errorJson,
                    [key_item]: validateMsg[key_item]
                }
                isValid = false
            }else{
                errorJson = {
                    ...errorJson,
                    [key_item]: ""
                }
            }
        })
        setFormInputEr({
            ...formInputEr,
            ...errorJson
        })
        return isValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            let res = await APICall(ApiRoutes.addPropertyCategory , formInputs)
            if(res.status === 1){
                closePopup()
                fetchPropertyCategories("" ,"" ,1);
            }
            setLoading(false);
        }
    }
    return (<Modal className="modal-popup" size="xl" show={showAddCategory} onHide={closePopup}>
        <Modal.Header closeButton>
            <Modal.Title>Add Property Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={handleSubmit}>
                <Row>

                    <InputText
                        type={"text"}
                        title={"Property Type Code"}
                        value={formInputs.term_code}
                        onChange={(value) => {
                            setFormInput({
                                ...formInputs,
                                term_code: value,
                            })
                        }}
                        validationText={formInputEr.term_code}

                    />
                    <InputText
                        type={"text"}
                        title={"Property Type Name"}
                        value={formInputs.term}
                        onChange={(value) => {
                            setFormInput({
                                ...formInputs,
                                term: value,
                            })
                        }}
                        validationText={formInputEr.term}

                    />
                    <InputText
                        type={"text"}
                        title={"Property Type Details"}
                        value={formInputs.term_detail}
                        onChange={(value) => {
                            setFormInput({
                                ...formInputs,
                                term_detail: value,
                            })
                        }}
                        validationText={formInputEr.term_detail}

                    />
                </Row>
                <div className="col-12 d-flex justify-content-end">
                    {!loading ? (
                        <>
                            <Button
                                type="submit"
                                variant="primary"
                                className="btn-15"

                            >
                                Save
                            </Button>
                        </>
                    ) : (
                        <Button variant="primary" type="button" className="btn-15">
                            <Spinner animation="border" size="sm" /> Loading...
                        </Button>
                    )}
                </div>
            </Form>
        </Modal.Body>
    </Modal>)
}
