import React, { useState } from 'react'
import { Button, Modal, Spinner } from "react-bootstrap";
import DropDown from "../../components/common/DropDown";
import { ApiRoutes, Enums } from '../../constants';
import { APICall } from '../../services/axiosService';

export default function AddGuardModal({ getDetails, contractId, selectedGuards, setSelectedGuards, guards, showAddGuard, setShowAddGuard, selectedGuardsType, setSelectedGuardsType }) {

  const [guardError, setGuardError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const closeAddGuardPopUp = () => {
    setShowAddGuard(false);
    setSelectedGuards([]);
    setGuardError("");
  }
  const handleAddGuardToContract = async () => {
    if (selectedGuards.length) {
      setIsLoading(true);
      let guard_list = []
      selectedGuards.forEach((selectedGuard) => {
        guard_list = [
          ...guard_list ,
          {
            guard_id : selectedGuard.value,
            guard_type : selectedGuardsType
          }
        ]
      })
      let data = {
        contract_id: contractId,
        guard_list: guard_list
      };
      let res = await APICall(ApiRoutes.addStaffMember, data)
      if (res.status == 1) {
        setIsLoading(false);
        closeAddGuardPopUp();
        getDetails();
      }
    } else {
      setGuardError("Atleast one guard should be selected.")
    }
  }

  return (<Modal className="modal-popup" show={showAddGuard} onHide={closeAddGuardPopUp}>
    <Modal.Header closeButton>
      <Modal.Title>Select Guard</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <DropDown
        style={{ div: "col-md-12 col-12" }}
        data={guards}
        title={"Guard"}
        value={guards.value}
        isMulti={true}
        onChange={(value) => setSelectedGuards(value)}
        required
        validationText={guardError}
      />

      <select onChange={(e) => {

        setSelectedGuardsType(e.target.value ? e.target.value : null)
      }} class="form-select" aria-label="Default select example">
        <option selected={selectedGuardsType ? true : false} value="" >Select Guard Type</option>
        {Enums.GuardTypesDropDown.map((itm) => {
          return (<option selected={selectedGuardsType == itm.value ? true : false} value={itm.value}>{itm.value}</option>)

        })}

      </select>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="outline-primary" onClick={closeAddGuardPopUp}>
        Close
      </Button>
      {isLoading === false ? (
        <>
          <Button variant="primary"
            onClick={handleAddGuardToContract}
          >
            Save
          </Button>
        </>
      ) : (
        <Button variant="primary">
          <Spinner animation="border" size="sm" /> Loading...
        </Button>
      )}
    </Modal.Footer>
  </Modal>)
}
