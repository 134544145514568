import React, { useEffect, useState } from 'react'
import { ApiRoutes, Enums } from '../../../constants';
import { APICall } from '../../../services/axiosService';
import { commonService } from '../../../services/common.service'
import { useNavigate , Link } from "react-router-dom";
import { Breadcrumb, Button, InputGroup } from "react-bootstrap";
import { Form, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { alertService, AlertType } from "../../../services/alert.service";
import DataTable from "react-data-table-component";
import InputText from "../../../components/form/inputText";
import Switch from "react-switch";
import SendReview from '../../Review/SendReview';
import NewLoader from '../../../components/loader/NewLoader';


export default function Index({getDetails ,  contractId , formInputs}) {

    const navigate = useNavigate();
    const [txtSearch, setTxtSearch] = useState("");
    const [pageNo, setPageNo] = useState(1);
    const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [totalRows, setTotalRows] = useState(0);
    const [reviewList, setReviewList] = useState({
        isData: false,
        data: [],
    });
    const [dataTableLoading, setDataTableLoading] = useState(false);
    const [status, setStatus] = useState(null);

    const [reviewModal, setReviewModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [reviewId, setReviewId] = useState(null);
    const [isAddEdit, setIsAddEdit] = useState(false);

    React.useEffect(() => {
        fetchReviews("", "", 1);
    }, [pageNo, perPageRecords]);

    const onSwitchToggle = async (value, item, index) => {
        const data = await APICall(ApiRoutes.reviewStatusUpdate, {
            id: item.review_id,
            is_active: value ? 1 : 0,
        });
        if (data) {
            setReviewList((prevState) => ({
                ...prevState,
                data: prevState.data.map((MapItem, MapIndex) => ({
                    ...MapItem,
                    is_active:
                        MapIndex === index ? (value ? "1" : "0") : MapItem.is_active,
                })),
            }));
        }
    };

    // const fetchReviews = async () => {
    //   let res = await APICall(ApiRoutes.fetchReviews, { contract_id: contractId })
    // }
    // useEffect(() => {
    //   fetchReviews()
    // }, []);

    async function fetchReviews(
        column = "",
        sortDirection = "",
        isFilter = 0
    ) {
        setDataTableLoading(true);
        const requestMetadata = await APICall(ApiRoutes.fetchReviews, {
            contract_id: contractId,
            is_all : true,
            page: pageNo,
            limit: perPageRecords,
            order: { column: column, dir: sortDirection },
            is_filter: isFilter,
            filter: {
                search_text: txtSearch,
                start_date:
                    from != null
                        ? commonService.convertLocalToUTC(
                            commonService.formatDate(from) + " 00:00:00"
                        )
                        : null,
                end_date:
                    to != null
                        ? commonService.convertLocalToUTC(
                            commonService.formatDate(to) + " 23:59:59"
                        )
                        : null,
                status: status,
            },
        });

        if (requestMetadata) {
            setReviewList({ isData: true, data: requestMetadata.data.list });

            setTotalRows(requestMetadata.data.total);
            setIsAddEdit(requestMetadata.data.is_add_edit)
        }
        setDataTableLoading(false);
    }

    const handleSort = async (column, sortDirection) => {
        fetchReviews(column.sortField, sortDirection, 1);
    };

    const searchDateWise = (e) => {
        e.preventDefault();
        let isValidate = true;
        if (from || to) {
            if (from && to) {
                const dateFrom = new Date(from);
                const dateTo = new Date(to);
                if (dateFrom > dateTo) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: "End date must be after start date",
                    });
                    isValidate = false;
                }
            }
        }
        if (isValidate) {
            fetchReviews("", "", 1);
        }
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPageRecords(newPerPage);
    };

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const resetFilter = () => {
        setTo(null);
        setFrom(null);
        setStatus(null)
        setTxtSearch("")
        fetchReviews("", "", 0);
    };

    React.useEffect(() => {
        fetchReviews("", "", 1);
    }, [txtSearch]);
    // property_name
    const columns = [
        {
            name: "Company/Branch",
            selector: (row) => <span onClick={()=> {
                    if(row.to_association_type_term == Enums.UserRole.company){
                        navigate('/contract/company/profile' ,  { state: { comapny_id: row.to_association_id} }) 
                    }else if(row.to_association_type_term == Enums.UserRole.branch_manager){
                        navigate('/contract/branch/profile' , { state: { branch_id: row.to_association_id} }) 
                    }
               }} className="cursor-pointer nav-link p-0">{row.to_association_name}</span>,
            sortable: true,
            sortField: "to_association_name",
        },
        {
            name: "Review",
            selector: (row) => <span className="cursor-pointer" title={row.review}>{row.review}</span>,
            sortable: true,
            sortField: "review",
        },
        {
            name: "Ratting",
            selector: (row) => row.rating,
            sortable: true,
            sortField: "rating",
        },
        // {
        //     name: "Status",
        //     selector: (row) => row.is_active,
        //     sortable: true,
        //     sortField: "is_active",
        //     cell: (row, index, column, id) => {
        //         return (
        //             <span className="justify-content-center align-items-center d-flex">
        //                 <Switch
        //                     uncheckedIcon={false}
        //                     checkedIcon={false}
        //                     onChange={(value) => onSwitchToggle(value, row, index)}
        //                     checked={row.is_active == 1 ? true : false}
        //                     height={20}
        //                     width={40}
        //                     onColor="#263A96"
        //                 />
        //             </span>
        //         );
        //     },
        // },
        {
            name: "Reviewed At",
            sortable: false,
            sortField: "created_at",
            cell: (row, index, column, id) =>
                commonService.convertUTCToLocal(row.created_at),
        },
        {
            name: "Action",
            selector: (row) => row.year,
            cell: (row, index, column, id) =>

                <div className="cursor-pointer justify-content-center align-items-center d-flex">
                    <div
                        onClick={() => {
                            setIsEdit(true)
                            setReviewModal(true)
                            setReviewId(row.review_id)
                        }}
                    >
                        <i className="fa fa-edit text-primary"></i>
                    </div>
                </div>
            ,
        },
    ];
    const filterList = (value) => {
        // quotation_status
        if (value) {
            setStatus(parseInt(value));
        } else {
            setStatus(null);
        }
    };

    return (<>
        <div className="row">
            
            <div>
                <Card>
                    <Card.Body className="p-3">
                        <div className="col-12 row mb-3 mt-2">
                            <div className="col-3">
                                <InputGroup>
                                    <DatePicker
                                        className="datepicker form-control"
                                        selected={from ? from : ""}
                                        dateFormat="MM-dd-yyyy"
                                        placeholderText="Start Date"
                                        onChange={(date) => {
                                            if (date) {
                                                setFrom(date);
                                            } else {
                                                setFrom(null);
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </div>
                            <div className="col-3">
                                <div>
                                    <InputGroup>
                                        <DatePicker
                                            className="datepicker form-control"
                                            selected={to ? to : ""}
                                            dateFormat="MM-dd-yyyy"
                                            placeholderText="End Date"
                                            onChange={(date) => {
                                                if (date) {
                                                    setTo(date);
                                                } else {
                                                    setTo(null);
                                                }
                                            }}
                                        />
                                    </InputGroup>
                                </div>
                            </div>
                            {/* <div className="col-3">
                                <Form.Select onChange={(e) => { filterList(e.target.value) }}>
                                    <option value="" selected={!status && true}>
                                        Select Leave Status
                                    </option>
                                    <option
                                        value="1"
                                        selected={status == "1" && true}
                                    >
                                        Active
                                    </option>
                                    <option
                                        value="0"
                                        selected={status == "0" && true}
                                    >
                                        Inactive
                                    </option>
                                </Form.Select>
                            </div> */}
                            <div className="col-3 d-flex">
                                <div className="ms-2 me-2">
                                    <Button
                                        onClick={searchDateWise}
                                        variant="outline-primary"
                                        type="button"
                                    >
                                        <i className="fa fa-search"></i>
                                    </Button>
                                </div>
                                <div className="ms-2 me-2">
                                    <Button
                                        onClick={resetFilter}
                                        variant="outline-primary"
                                        type="button"
                                    >
                                        <i className="fa fa-refresh"></i>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </div>
<SendReview isAddEdit={isAddEdit} getDetails={getDetails} fetchApiAfter={fetchReviews} reviewId={reviewId} setReviewId={setReviewId}  contract_id={contractId} setIsEdit={setIsEdit} is_edit={isEdit} reviewModal={reviewModal} to_association_id={formInputs?.association_id}  to_association_type_term={formInputs?.association_type_term} setReviewModal={setReviewModal} />

      <div className="col-12 d-flex align-items-center justify-content-end mb-3 mt-2">
            {/* <Link to="/contract/send/review"> */}
              <Button onClick={() => {setReviewModal(true) }} className="btn-15" variant="primary" type="button">
                <i className="fa-regular fa-plus"></i> Send Review 
              </Button>
            {/* </Link> */}
        </div>
        <DataTable
            columns={columns}
            data={reviewList.data}
            progressPending={dataTableLoading}
            progressComponent={<NewLoader />}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onSort={handleSort}
            paginationPerPage={perPageRecords}
            actions={<>
                <div className="col-4">
                    <InputText
                        formGroupClass={"mb-0"}
                        size={'12'}
                        type={"text"}
                        Label={false}
                        placeholder={"Search..."}
                        value={txtSearch}
                        onChange={(value) => {
                            if (value) {
                                setTxtSearch(value)
                            } else {
                                setTxtSearch(value)
                            }
                        }}
                    />
                </div>
            </>}
        />
    </>)
}
