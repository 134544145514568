import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ApiRoutes, badgesEnum, ComplaintEnum, Enums } from "../../constants";

import { Breadcrumb, Button, InputGroup } from "react-bootstrap";
import { Form, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { alertService, AlertType } from "../../services/alert.service";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import DataTable from "react-data-table-component";
import InputText from "../../components/form/inputText";
import DropDown from '../../components/common/DropDown';
import { useEffect } from "react";
import NewLoader from "../../components/loader/NewLoader";

export default function Index() {

  const navigate = useNavigate();
  const [pageNo, setPageNo] = useState(1);
  const [txtSearch, setTxtSearch] = useState("");
  const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
  const [complaintsFrom, setComplaintsFrom] = useState(null);
  const [complaintsTo, setComplaintsTo] = useState(null);
  const [complaintsTotalRows, setComplaintsTotalRows] = useState(0);
  const [complaintList, setComplaintList] = useState({
    isData: false,
    data: [],
  });
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [complaintStatus, setComplaintStatus] = useState(null);
  const [contractList, setContractList] = useState([]);
  const [selectedPropertyList, setSelectedPropertyList] = useState([]);

  React.useEffect(() => {
    getComplaintList('', '', 1);
  }, [txtSearch,pageNo, perPageRecords]);

  async function getComplaintList(
    column = "",
    sortDirection = "",
    isFilter = 0
  ) {
    setDataTableLoading(true);
    setComplaintList({ isData: false, data: [] });
    let newSelectedArray = [];
    selectedPropertyList.forEach((property_item)=>{
        newSelectedArray = [...newSelectedArray , property_item.value]
    })
    const requestMetadata = await APICall(ApiRoutes.getComplaints, {
      page: pageNo,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      is_filter: isFilter,
      filter: {
        search_text: txtSearch,
        start_date:
          complaintsFrom != null
            ? commonService.convertLocalToUTC(
              commonService.formatDate(complaintsFrom) + " 00:00:00"
            )
            : null,
        end_date:
          complaintsTo != null
            ? commonService.convertLocalToUTC(
              commonService.formatDate(complaintsTo) + " 23:59:59"
            )
            : null,
        ticket_status: complaintStatus,
        contract_list: newSelectedArray
      }
    });

    if (requestMetadata) {
      setComplaintList({ isData: true, data: requestMetadata.data.list });

      setComplaintsTotalRows(requestMetadata.data.total);
      setDataTableLoading(false);
    }
    
  }

  const handleSort = async (column, sortDirection) => {
    getComplaintList(column.sortField, sortDirection, 1);
  };

  const searchDateWise = (e) => {
    e.preventDefault();
    let isValidate = true;
    if (complaintsTo || complaintsFrom) {
      if (complaintsTo && complaintsFrom) {
        const dateFrom = new Date(complaintsTo);
        const dateTo = new Date(complaintsFrom);
        if (dateFrom > dateTo) {
          alertService.alert({
            type: AlertType.Error,
            message: "End date must be after start date",
          });
          isValidate = false;
        }
      }
    }
    if (isValidate) {
      getComplaintList("", "", 1);
      setComplaintsTotalRows(1)
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const resetFilter = () => {
    setComplaintsTo(null);
    setSelectedPropertyList([])
    setComplaintsFrom(null);
    setTxtSearch("")
    setComplaintStatus(null)
    getComplaintList("", "", 0);
  };
  const fetchReviewContractList = async () => {
    let res = await APICall(ApiRoutes.contractOptions)
    if (res.status === 1) {
        setContractList(res.data.contract_list)
    }
}

useEffect(() => {
    fetchReviewContractList()
}, []);

  // React.useEffect(() => {
  //   getComplaintList("", "", 1);
  // }, [txtSearch]);
  const companyTblColumns = [
    {
      name: "Property Name",
      selector: (row) => <span className="cursor-pointer text-primary" onClick={() => navigate(`/contracts/view`, {
        state: {
          id: row.contract_id,
        },
      })}>{row.property_name}</span>,
      sortable: true,
      sortField: "property_name",
    },
    {
      name: "Property Manager",
      selector: (row) => <span className="cursor-pointer text-primary" onClick={() => navigate(`/clients/view/${row.client_id}`)}>{row.client_name}</span>,
      sortable: true,
      sortField: "client_name",
    }, {
      name: "Submitted By",
      // selector: (row) => <span className="cursor-pointer text-primary" onClick={() => navigate(`/clients/view/${row.client_id}`)}>{row.applicant_name}</span>,
      selector: (row) => row.applicant_name,
      sortable: true,
      sortField: "applicant_name",
    },
    {
      name: "Complaint Date",
      selector: (row) => row.created_at,
      sortable: true,
      sortField: "created_at",
      cell: (row, index, column, id) =>
        commonService.convertUTCToLocal(row.created_at,'DD-MM-YYYY, hh:mm A'),
    },
    {
      name: "Subject",
      selector: (row) => row.subject,
      sortable: true,
      width: "180px",
      sortField: "subject",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      width: "180px",
      sortField: "description",
    },
    {
      name: "Status",
      sortable: false,
      sortField: "ticket_status",
      cell: (row, index, column, id) => {
        return (
          <span className="justify-content-center align-items-center d-flex">
            {ComplaintEnum[row.ticket_status]}
          </span>
        );
      },
    },


    {
      name: "Action",
      selector: (row) => row.year,
      cell: (row, index, column, id) => (
        <div className="justify-content-center align-items-center d-flex">
          <Button
            style={{ fontSize: "0.7rem", padding: "0.3rem 1rem" }}
            variant="primary"
            type="button"
            onClick={() => {
              navigate(`/complaint/view/${row.ticket_request_id}`);
            }}
          >
            View
          </Button>
        </div>
      ),
    },
  ];
  const filterComplainList = (value) => {
    // quotation_status
    if (value) {
      setComplaintStatus(value);
    } else {
      setComplaintStatus(null);
    }
  };

  return (<>
    <div className="row">
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top d-flex align-items-center">
            <h2 className="content-header-title float-start mb-0">
            Complaint List
            </h2>
            <Breadcrumb className="breadcrumb-chevron">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/complaint">Setup</Breadcrumb.Item>
              <Breadcrumb.Item active>Request / Complaint</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div>
        <Card>
          <Card.Body className="p-3">
            <div className="col-12 row mb-3 mt-2">

              <div className="col-3">
                <InputGroup>
                  <DatePicker
                    className="datepicker form-control"
                    selected={complaintsFrom ? complaintsFrom : ""}
                    dateFormat="MM-dd-yyyy"
                    placeholderText="Start Date"
                    onChange={(date) => {
                      if (date) {
                        setComplaintsFrom(date);
                      } else {
                        setComplaintsFrom(null);
                      }
                    }}
                  />
                </InputGroup>
              </div>
              <div className="col-3">
                <div>
                  <InputGroup>
                    <DatePicker
                      className="datepicker form-control"
                      selected={complaintsTo ? complaintsTo : ""}
                      dateFormat="MM-dd-yyyy"
                      placeholderText="End Date"
                      onChange={(date) => {
                        if (date) {
                          setComplaintsTo(date);
                        } else {
                          setComplaintsTo(null);
                        }
                      }}
                    />
                  </InputGroup>
                </div>
              </div>
              <div className="col-6">
                                <DropDown
                                    value={selectedPropertyList}
                                    data={contractList}
                                    style={{ div: "col-12" }}
                                    isMulti={true}
                                    onChange={(value) =>
                                        setSelectedPropertyList(value)
                                    }
                                    placeholder="Select Contract/Property"
                                    required
                                // validationText={formInputsErrors.duration_term}
                                />
                            </div>
              <div className="col-3">
                <Form.Select onChange={(e) => { filterComplainList(e.target.value) }}>
                  <option value="" selected={!complaintStatus && true}>
                    Select Complaint Status
                  </option>
                  {Object.keys(Enums.ComplainStatus).map((status_term) => {
                    return <option
                      value={status_term}
                      selected={status_term == complaintStatus && true}
                    >
                      {Enums.ComplainStatus[status_term]}
                    </option>
                  })}
                </Form.Select>
              </div>
              <div className="col-3 d-flex">
                <div className="ms-2 me-2">
                  <Button
                    onClick={searchDateWise}
                    variant="outline-primary"
                    type="button"
                  >
                    <i className="fa fa-search"></i>
                  </Button>
                </div>
                <div className="ms-2 me-2">
                  <Button
                    onClick={resetFilter}
                    variant="outline-primary"
                    type="button"
                  >
                    <i className="fa fa-refresh"></i>
                  </Button>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
    <DataTable
      columns={companyTblColumns}
      data={complaintList.data}
      progressPending={dataTableLoading}
      progressComponent={<NewLoader />}
      pagination
      paginationServer
      paginationTotalRows={complaintsTotalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      onSort={handleSort}
      paginationPerPage={perPageRecords}
      actions={<>
        <div className="col-4">
          <InputText
            formGroupClass={"mb-0"}
            size={'12'}
            type={"text"}
            Label={false}
            placeholder={"Search..."}
            value={txtSearch}
            onChange={(value) => {
              if (value) {
                setTxtSearch(value)
              } else {
                setTxtSearch(value)
              }
            }}
          />
        </div>
      </>}
    />
  </>)
}
