import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiRoutes, Enums, contractScheduleStatusEnum } from "../../constants";
import { Breadcrumb, Button, InputGroup, Badge } from "react-bootstrap";
import DropDown from "../../components/common/DropDown";
import { Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { alertService, AlertType } from "../../services/alert.service";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import DataTable from "react-data-table-component";
import InputText from "../../components/form/inputText";
import Export from "../../components/common/Export";
import NewLoader from "../../components/loader/NewLoader";


export default function ReplacedGuardsList({leave_id}) {

    const navigate = useNavigate();
    const [pageNo, setPageNo] = useState(1);
    const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [totalRows, setTotalRows] = useState(0);
    const [list, setList] = useState({
        isData: false,
        data: [],
    });
    const [dataTableLoading, setDataTableLoading] = useState(false);
    const [txtSearch, setTxtSearch] = useState("");


    React.useEffect(() => {
        getDataList();
    }, [pageNo, perPageRecords]);


    useEffect(() => {
        getDataList("", "", 1);
    }, [txtSearch]);

    async function getDataList(
        column = "",
        sortDirection = "",
        isFilter = 0
    ) {
        setDataTableLoading(true);

        let newSelectedArray = [];
     



        setList({ isData: false, data: [] });
        const requestMetadata = await APICall(ApiRoutes.leaveGuardReplaceList, {
            page: pageNo,
            limit: perPageRecords,
            order: { column: column, dir: sortDirection },
            is_filter: isFilter,
            leave_id :leave_id,
            filter: {
                search_text: txtSearch,
            }
        });

        if (requestMetadata) {
            setList({ isData: true, data: requestMetadata.data.list });
            setTotalRows(requestMetadata.data.total);
        }
        setDataTableLoading(false);
    }

    const handleSort = async (column, sortDirection) => {
        getDataList(column.sortField, sortDirection , 1);
    };


    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPageRecords(newPerPage);
    };

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const resetFilter = () => {
        setTo(null);
        setFrom(null);
        setTxtSearch("")
        getDataList("", "", 0);

    };


    let key_headers = {
        "schedule_date": {
            'text': "Schedule Date"
        },
        "property_name": {
            'text': "Property Name"
        },
        "client_name": {
            'text': "Property Manager",
            'is_date': false
        },
        "guard_name": {
            'text': "Guard Name",
            'is_date': false
        },
        "Clock In": {
            'text': "schedule_start_time",
            'is_date': true,
            'date_format': 'HH:mm'
        },
        "Clock Out": {
            'text': "schedule_end_time",
            'is_date': true,
            'date_format': 'HH:mm'
        },
        // "dummy_leave": {
        //     'text': "leave",
        //     'is_date': false
        // },
        "dummy_review": {
            'text': "Remark",
            'is_date': false
        },
        "Proxy": {
            'text': "is_proxy",
            'is_date': false
        }
    }

   
    const columns = [
        {
            name: "Guard Name",
            selector: (row) => row.replacement_name,
            sortField: "replacement_name",
            sortable: true,
        },
        {
            name: "Start Date",
            selector: (row) => row.start_date ? commonService.convertUTCToLocal(row.start_date) : "",
            sortField: "start_date",
            sortable: true,
        },
        {
            name: "End Date",
            selector: (row) => row.end_date ? commonService.convertUTCToLocal(row.end_date) : "",
            sortField: "end_date",
            sortable: true,
        },
        {
            name: "Replaced By",
            selector: (row) => row.replaced_by,
            sortField: "replaced_by",
            sortable: true,
        },
        
        
    ];


  return (<>
        <div className="z-adjust-for-dropdown hideDiv">
            <DataTable
                columns={columns}
                data={list.data}
                progressPending={dataTableLoading}
                progressComponent={<NewLoader />}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onSort={handleSort}
                paginationPerPage={perPageRecords}
                actions={<>
                    <div className="col-4">
                        <InputText
                            formGroupClass={"mb-0"}
                            size={'12'}
                            type={"text"}
                            Label={false}
                            placeholder={"Search..."}
                            value={txtSearch}
                            onChange={(value) => {
                                if (value) {
                                    setTxtSearch(value)
                                } else {
                                    setTxtSearch(value)
                                }
                            }}
                        />
                    </div>
                </>}
            />
        </div>
    </>)
}
