export const Enums = {
  UserRole: {
    admin: "admin",
    company: "company",
    branch_manager: "branch_manager",
    client: "client",
    client_contact: "client_contact",
    guard: "guard",
    incharge: "incharge",
  },
  UserRights: {
    company: "company",
    branch: "branch_manager",
    guard: "guards",
    incharge: "incharges",
    properties: "properties",
    users: "client_contacts",
  },
  WeekDays: {
    sunday: "sunday",
    monday: "monday",
    tuesday: "tuesday",
    wednesday: "wednesday",
    thursday: "thursday",
    friday: "friday",
    saturday: "saturday",
  },
  ComplainStatus: {
    pending: "Pending",
    rejected: "Rejected",
    accepted: "Accepted",
    completed: "Completed",
  },
  ContractStatus: {
    active: "Active",
    expired: "Expired",
  },
  QuotationRequests: {
    pending: "New Request",
    // quote_rec: "Quotation Received",
    in_progress: "In Progress",
    rejected: "Rejected",
    accepted: "Accepted",
    confirmed: "Confirmed",
    completed: "Completed",
    expired: "Expired",
  },
  TermCategoryCode: {
    property_category: "property_category",
  },
  CheckPointStatus: {
    pending: 'Pending',
    active: 'Active',
    inactive: 'Inactive'
  },
  BranchStatusDtls: {
    pending: 'Pending',
    active: 'Active',
    rejected: 'Rejected'
  },
  BranchStatus: {
    pending: 'pending',
    active: 'active',
    rejected: 'rejected'
  },
  GuardStatus: {
    pending: "pending",
    active: "active",
    rejected: "rejected",
  },
  InchargeStatus: {
    rejected: "rejected",
    pending: "pending",
    active: "active",
  },
  GuardTypes : {
    patrolling_guard : "patrolling_guard",
    gate_guard : 'gate_guard'
  },
  GuardTypesDetails : {
    patrolling_guard : "Patrolling Guard",
    gate_guard : 'Gate Guard'
  },
  GuardTypesDropDown :[
    {
      label: 'Patrolling Guard',
      value: 'patrolling_guard',
    },
    {
      label: 'Gate Guard',
      value: 'gate_guard',
    },
  ],

  BranchDropDown: [
    {
      label: 'Pending',
      value: 'pending',
    },
    {
      label: 'Accept',
      value: 'active',
    },
    {
      label: 'Reject',
      value: 'rejected',
    },
  ],
  GuardInchargeDropDown: [
    {
      label: 'Pending',
      value: 'pending',
    },
    {
      label: 'Accept',
      value: 'active',
    },
    {
      label: 'Reject',
      value: 'rejected',
    },
  ],
  UserRoleDetail: {
    admin: "Admin",
    company: "Company",
    branch_manager: "Branch Manager",
    client: "Property Manager",
    guard: "Guard",
    incharge: "Incharge",
  },

  DefaultPerPageLength: 20,

};
